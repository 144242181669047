import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";

import ScrollUp from "../../components/ScrollUp";
import ChatBox from "../../components/ChatBox";
import Footer from "../../components/Section/Footer";

import bgr from "../../assets/images/wedding-banner.png";
import PriceList from "../../components/Section/Price List";
import { PageBanner } from "../../components/Elements";

function WeddingPackage() {
  return (
    <Fragment>
      <ChatBox />
      <Navbar />
      <PageBanner
        text='Packages'
        header='Wedding Lesson Package'
        breadcrumbs={[
          { label: "Home", url: "/" },
          { label: "Wedding", url: "#" },
          { label: "Wedding Lesson Package", url: "#" },
        ]}
        bgr={bgr}
      />
      <PriceList />
      <ScrollUp />
      <Footer />
    </Fragment>
  );
}

export default WeddingPackage;
