import { Container, Grid, styled } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Description, PricePlan, Title } from "../../Elements";

const Section = styled(Box)({
  backgroundColor: "#fff", //'#f8f8f8',
  position: "relative",
  padding: "130px 0",
  zIndex: 1,
  "&:before": {
    position: "absolute",
    width: "50%",
    height: "100%",
    right: 0,
    top: 0,
    content: '""',
    backgroundColor: "#eef4fc",
    zIndex: -1,
  },
});

const Button = styled("a")({
  textTransform: "uppercase",
  fontSize: "14px",
  fontWeight: 700,
  display: "inline-block",
  padding: "20px 40px",
  backgroundColor: "#af0420",
  borderRadius: "5px",
  marginTop: "30px",
  color: "#fff",
  textDecoration: "none",
  transition: "all 0.4s ease-in-out",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#000",
  },
});

function PriceList(props) {
  const { t } = useTranslation();
  const priceList = [
    {
      id: 1,
      header: "Bronze Plan",
      title: "Simple Choreography pack",
      description:
        "If you’ve decided to learn a full yet simple choreography to wow your guests, this package is for you.",
      price: {
        currency: "₺",
        value: "1000",
        info: "for 4-lessons",
      },
      features: [
        {
          id: 1,
          caption: "Basic Steps",
        },
        { id: 2, caption: "Ocho & Basic Steps" },
        {
          id: 3,
          caption: "Rotation & Circular Movements",
        },
        {
          id: 4,
          caption: "Balance & Position",
        },
      ],
      caption: "Get Started",
      url: "#",
    },
    {
      id: 2,
      header: "Gold Plan",
      title: "ULTIMATE Choreography pack",
      description:
        "If you prefer something more impressive with lifts and more, and wow your guests, try the ULTIMATE package. For memories of a lifetime.",
      price: {
        currency: "₺",
        value: "1600",
        info: "for 5-lessons",
      },
      features: [
        {
          id: 1,
          caption: "Basic Steps",
        },
        { id: 2, caption: "Ocho & Basic Steps" },
        {
          id: 3,
          caption: "Rotation & Circular Movements & Special Decoration",
        },
        {
          id: 4,
          caption: "Balance & Special Position",
        },
        {
          id: 5,
          caption: "Special Position",
        },
      ],
      caption: "Get Started",
      url: "#",
    },
  ];
  return (
    <Section>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} xl={4} sx={{ mb: { xs: 5, lg: 5, md: 5, xl: 0 } }}>
            <Title
              fadeTitle={"Wedding"}
              title={"A beautiful tango for your wedding"}
              header={"Argentine tango"}
            />
            <Description
              description={
                <>
                  <p>
                    Sed ut perspiciatis unde omnis natus error sit voluptatem accusa ntium doloreme
                    laudantium totam rem aperiamea queipsa quae abillo inve ntore veritatis et quasi
                    architecto beatae vitae dicta sunt explicabo.
                  </p>
                </>
              }
            />

            <Button href='/blog/argentine-tango'>
              Read more{" "}
              <ArrowForwardIcon fontSize='small' sx={{ ml: "5px", verticalAlign: "middle" }} />
            </Button>
          </Grid>
          <Grid item xl={7} xs={12} sx={{ ml: { xl: "8.333333%" } }}>
            <PricePlan priceList={priceList} />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default PriceList;
