import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";

import ScrollUp from "../../components/ScrollUp";
import ChatBox from "../../components/ChatBox";
import Footer from "../../components/Section/Footer";

import bgr from "../../assets/images/class-schedule.png";
import About from "../../components/Section/About";
import Calendar from "../../components/Section/Calendar";
import { PageBanner } from "../../components/Elements";

function Beginner() {
  return (
    <Fragment>
      <ChatBox />
      <Navbar />
      <PageBanner text='Beginner' header='registeration is open!' breadcrumb='Beginner Course' bgr={bgr} />
      <About />
      <Calendar />
      <ScrollUp />
      <Footer />
    </Fragment>
  );
}

export default Beginner;
