import React, { Fragment } from "react";

import Navbar from "../../components/Navbar";
import { PageBanner } from "../../components/Elements";
import About from "../../components/Section/About";
import ChatBox from "../../components/ChatBox";
import ScrollUp from "../../components/ScrollUp";
import Footer from "../../components/Section/Footer";

import banner from "../../assets/images/class-schedule.png";

// Timeline
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import CelebrationIcon from "@mui/icons-material/Celebration";

import Typography from "@mui/material/Typography";
// End of Timeline

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const photos = [
  {
    src: require("../../assets/images/gallery/19-oct-2021/DSC01266.jpg"),
    width: 2048,
    height: 1365,
  },
  {
    src: require("../../assets/images/gallery/19-oct-2021/DSC01269.jpg"),
    width: 2048,
    height: 1365,
  },
  {
    src: require("../../assets/images/gallery/19-oct-2021/DSC01272.jpg"),
    width: 2048,
    height: 1365,
  },
];

const timelines = [
  {
    id: 1,
    date: "Oct 19",
    title: "Kemar",
    subtitle: "Cozy Tango 19",
  },
  {
    id: 2,
    date: "Oct 20",
    title: "Kemar",
    subtitle: "Cozy Tango 20",
  },
  {
    id: 1,
    date: "Oct 21",
    title: "Kemar",
    subtitle: "Cozy Tango 21",
  },
  {
    id: 1,
    date: "Oct 22",
    title: "Kemar",
    subtitle: "Cozy Tango 22",
  },
];

function Gallery() {
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(-1);

  const colors = ["success", "error", "secondary", "warning"];

  return (
    <Fragment>
      <ChatBox />
      <Navbar />
      <PageBanner
        text='photo gallery'
        header='Photo Gallery'
        breadcrumbs={[
          { label: "Home", url: "/" },
          { label: "About", url: "/our-academy" },
          { label: "Photo Gallery", url: "/gallery" },
        ]}
        bgr={banner}
      />
      <Lightbox
        slides={photos}
        open={open}
        close={() => setOpen(false)}
        // open={index >= 0}
        // index={index}
        // close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />

      <About
        title={"Welcome to our memories"}
        header={"Let's see together"}
        description={
          <span>
            We were with you for more than two decades and spent beautiful and memorable moments
            together, reviewing them makes us happy and reminds us of the beauty of moments.
            <strong>Tango is for creating beautiful memories</strong>
          </span>
        }
        goals={[
          {
            id: 1,
            title: "How can I see them?",
            description:
              "To view our memories, you can click on any of the memories from the time bar on the side and view the related photo album.",
          },
        ]}
      >
        <Timeline position='alternate'>
          {timelines &&
            timelines.map((timeline, index) => (
              <TimelineItem
                key={`timeline-${timeline.id}${index}`}
                onClick={() => setOpen(true)}
                style={{
                  cursor: "pointer",
                }}
              >
                <TimelineOppositeContent
                  sx={{ m: "auto 0" }}
                  align='right'
                  variant='body2'
                  color='text.secondary'
                >
                  {timeline.subtitle}
                  <br />
                  <button
                    style={{
                      padding: "5px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => setOpen(true)}
                  >
                    Open the album
                  </button>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot color={colors[Math.floor(Math.random() * colors.length)]}>
                    <CelebrationIcon />
                  </TimelineDot>
                  {index < timeline.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent sx={{ py: "12px", px: 2 }}>
                  <Typography variant='h6' component='span'>
                    {timeline.title}
                  </Typography>
                  <Typography>{timeline.date}</Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
        </Timeline>
      </About>

      <ScrollUp />
      <Footer />
    </Fragment>
  );
}

export default Gallery;
