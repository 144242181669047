import { Container, css, Grid, styled } from "@mui/material";
import React from "react";
import Paper from "@mui/material/Paper";
import bgr from "../../../assets/images/circle-bg.png";
import { ServiceBox, Title } from "../../Elements";

const Section = styled("section")({
  backgroundImage: `url(${bgr})`,
  backgroundColor: "rgba(17,17,17, .95)", //'#211e3b',
  backgroundPosition: "right bottom",
  padding: "130px 0px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  position: "relative",
});

const Row = styled("div")({
  width: "100%",
  marginInline: "-30px",
});

function Services({ title, header, style, services }) {
  return (
    <Section>
      <Container>
        <Row sx={{ mb: "50px" }}>
          <Grid item xs={12} lg={12}>
            <Title title={title} header={header} style={style} />
          </Grid>
        </Row>

        <Grid container spacing={2}>
          {services
            ? services.map((service, index) => (
                <Grid key={`${service.id}-${index}`} item md={6} xl={4} xs={12}>
                  <Paper elevation={3}>
                    <ServiceBox
                      src={service.src}
                      title={service.title}
                      description={service.description}
                      overlayTitle={service.overlayTitle}
                      overlayDescription={service.overlayDescription}
                    />
                  </Paper>
                </Grid>
              ))
            : null}
        </Grid>
      </Container>
    </Section>
  );
}

export default Services;
