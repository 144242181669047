import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { Container, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Title, Description } from "../../Elements";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Section = styled("section")({
  padding: "130px 0px",
  backgroundColor: "#eef4fc",
  position: "relative",
  overflow: "hidden",
  zIndex: 1,
});

const SVG = styled("svg")({
  position: "absolute",
  right: 0,
  bottom: "0px",
  zIndex: -1,
  width: "100%",
  height: "220px",
});

const IconBox = styled(Grid)({
  padding: "20px",
  borderRadius: "7px",
  backgroundColor: "#FFFFFF",
  webkitTransition: "all 0.4s ease-in-out",
  transition: "all 0.4s ease-in-out",
  textAlign: "center",
  marginTop: "30px",
  cursor: "pointer",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#af0420",
    webkitBoxShadow: "0px 10px 60px 0px rgba(175, 4, 32, 0.49)",
    boxShadow: "0px 10px 60px 0px rgba(175, 4, 32, 0.49)",
  },
});

const Icon = styled("img")({
  fontSize: "60px",
  color: "#000000",
  marginBottom: "15px",
  transition: "all 0.4s ease-in-out",
});

const IconCaption = styled("a")({
  fontFamily: '"Circular Std", sans-serif',
  fontWeight: 700,
  textTransform: "capitalize",
  fontSize: "18px",
  color: "inherit",
});

const Button = styled("a")({
  textTransform: "uppercase",
  fontSize: "14px",
  fontWeight: 700,
  display: "block",
  padding: "20px 40px",
  backgroundColor: "#af0420",
  borderRadius: "5px",
  marginTop: "30px",
  color: "#fff",
  textDecoration: "none",
  transition: "all 0.4s ease-in-out",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#000",
  },
});

function Features() {
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const features = [
    {
      id: 1,
      icon: require("../../../assets/images/in-studio.png"),
      caption: t("in-person"),
      url: "#",
    },
    {
      id: 2,
      icon: require("../../../assets/images/online.png"),
      caption: t("online"),
      url: "#",
    },
    {
      id: 3,
      icon: require("../../../assets/images/milonga.png"),
      caption: t("milonga"),
      url: "#",
    },
    {
      id: 4,
      icon: require("../../../assets/images/wedding-dance.png"),
      caption: t("wedding"),
      url: "#",
    },
    {
      id: 5,
      icon: require("../../../assets/images/private-class.png"),
      caption: t("private-lessons"),
      url: "#",
    },
    {
      id: 6,
      icon: require("../../../assets/images/salsa.png"),
      caption: t("practica"),
      url: "#",
    },
  ];
  return (
    <Section>
      <Container>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} xl={6}>
            <Grid container spacing={2} mt={3}>
              {features.map((feature, index) => (
                <Grid item xs={12} md={6} lg={4}>
                  <IconBox>
                    <Icon src={feature.icon} />
                    <IconCaption href={feature.url}>{feature.caption}</IconCaption>
                  </IconBox>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            xl={5}
            mt={5}
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              [theme.breakpoints.up("xl")]: {
                marginLeft: "8.3%",
              },
            }}
          >
            <Title title={t("we-provide-best")} header={t("learning-to-dance")} />
            <Description description={t("description-1")} />
            <Button href='#'>
              Learn more <ArrowForwardIcon fontSize='small' sx={{ ml: "5px", verticalAlign: "middle" }} />
            </Button>
          </Grid>
        </Grid>
      </Container>
      <SVG>
        <path
          fill-rule='evenodd'
          opacity='0.039'
          fill='rgba(17,17,17, .95)'
          d='M-0.000,232.999 C-0.000,232.999 239.131,-52.566 575.000,47.000 C910.869,146.565 1087.000,55.653 1231.000,19.999 C1375.000,-15.654 1800.820,-31.520 1915.000,232.999 C1445.000,232.999 -0.000,232.999 -0.000,232.999 Z'
        ></path>
      </SVG>
    </Section>
  );
}

export default Features;
