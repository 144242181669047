import { useTranslation } from "react-i18next";
import React from "react";
import { Box, Container, Grid, styled } from "@mui/material";
import { Diversity1, Nightlife } from "@mui/icons-material/";

import { Title, Description, CheckList, Circle, IconBox, ImageBox, Feature } from "../../Elements";

const Section = styled(Box)({
  backgroundColor: "#eef4fc", //'#f8f8f8',
  padding: "130px 0",
});

function About(props) {
  const { t } = useTranslation();
  const { children, short, image, title, header, description, goals, showFeatures } = props;
  const features = [
    {
      id: 1,
      title: "Tango Courses",
      description: "From Beginner to Advanced",
      icon: <Diversity1 color='error' fontSize='large' style={{ verticalAlign: "middle" }} />,
    },
    {
      id: 2,
      title: "Milongas",
      description: "We have a class and parties",
      icon: <Nightlife color='success' fontSize='large' style={{ verticalAlign: "middle" }} />,
    },
    {
      id: 3,
      title: "Specials",
      description: "Salsa Bachata, Sirtaki",
      icon: <Nightlife color='warning' fontSize='large' style={{ verticalAlign: "middle" }} />,
    },
  ];

  if (short)
    return (
      <Section>
        <Container>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} xs={12} sx={{ pr: { md: 5 } }}>
              <Title title={title} header={header} />
              <Description description={description} />
              <CheckList
                src={require("../../../assets/images/guarantee.png")}
                items={[
                  t("in-person-courses"),
                  t("short-sequences"),
                  t("practica"),
                  t("private-lessons"),
                ]}
              />
            </Grid>
            {/* <Box sx={{ width: { xs: '100%', md: '50%' }, mt: { xs: '40px', md: '0px' }, pl: { md: 5 } }}>
                                </Box> */}
            <Grid
              item
              xl={6}
              md={10}
              lg={6}
              xs={12}
              sx={{ mt: { xs: "40px", md: "0px" }, pl: { md: 5 } }}
            >
              <Box>
                <Circle
                  size={359}
                  back='#af0420'
                  front='rgba(215, 8, 8, 0.1)'
                  images={[
                    require("../../../assets/images/about-cozy.jpg"),
                    require("../../../assets/images/hassan-cozy.jpg"),
                  ]}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Section>
    );

  return (
    <Section>
      <Container>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12} sx={{ pr: { xs: 5 } }}>
            <Title title={title} header={header} />
            <Description description={description} />

            {goals &&
              goals.map((goal, index) => (
                <IconBox key={`icon-${index}`} title={goal.title} description={goal.description} />
              ))}
          </Grid>

          {/* class="col-lg-6 pl-lg-5 mt-5 mt-lg-0 col-12"  */}
          <Grid item lg={6} xs={12}>
            {children ? children : image && <ImageBox src={image} dir='r' />}
          </Grid>
        </Grid>

        {showFeatures && (
          <Grid
            container
            spacing={2}
            style={{
              paddingTop: "100px",
            }}
          >
            {features &&
              features.map((feature, index) => (
                <Grid item lg={4} md={6} xs={12}>
                  <Feature
                    key={`feature-${feature.id}-${index}`}
                    title={feature.title}
                    description={feature.description}
                    ICN={feature.icon}
                  />
                </Grid>
              ))}
          </Grid>
        )}
      </Container>
    </Section>
  );
}

export default About;
