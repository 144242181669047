import React, { useState, useEffect } from 'react'
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import { styled } from '@mui/material';

const ScrollUpButton = styled('button')({
    position: 'fixed',
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    backgroundColor: '#870319',
    bottom: '20px',
    right: '20px',
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.5s ease-in-out',
    zIndex: 999,
    cursor: 'pointer'
})

function ScrollUp() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <ScrollUpButton
            style={{
                opacity: isVisible ? 1 : 0,
                pointerEvents: isVisible ? 'auto' : 'none',
            }}
            onClick={handleScrollToTop}
        >
            <ArrowUpward sx={{color: '#fff'}} fontSize='small' />
        </ScrollUpButton>
    )
}

export default ScrollUp