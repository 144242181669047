import React from "react";
import { styled } from "@mui/material";

import bgr from "../../../assets/images/funfact-line.png";

const Wrapper = styled("section")({
  position: "relative",
  zIndex: 1,
  "&:before": {
    position: "absolute",
    bottom: 0,
    top: "auto",
    height: "50%",
    left: 0,
    width: "100%",
    content: '""',
    backgroundColor: "rgba(17,17,17, .95)", //'#211e3b',
    zIndex: -1,
  },
  "&:after": {
    position: "absolute",
    bottom: 0,
    height: "50%",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: "#eef4fc", //,'#f8f8f8'
    zIndex: -1,
    content: '""',
  },
});

const Container = styled("div")({
  maxWidth: "1200px",
  width: "100%",
  paddingInline: "30px",
  marginInline: "auto",
  color: "#fff !important",
});

const Grid = styled("div")({
  backgroundColor: "#af0420",
  backgroundImage: ` url(${bgr})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  position: "relative",
  backgroundPosition: "center",
  margin: 0,
  justifyContent: "space-around",
  display: "grid",
  msGridColumns: "1fr 1fr 1fr 1fr",
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  msGridRows: "auto",
  gridTemplateRows: "auto",
  WebkitBoxPack: "justify",
  msFlexPack: "justify",
  textAlign: "center",
  WebkitBoxAlign: "center",
  msFlexAlign: "center",
  alignItems: "center",
  borderRadius: "7px",
  padding: "80px 0px 70px",
});

const Item = styled("div")({});

const Icon = styled("div")({
  fontSize: "42px",
  lineHeight: 1,
  marginBottom: "20px",
});

const IMG = styled("img")({
  width: "72px",
  height: "72px",
  filter: "brightness(0) invert(1)",
});

const Header = styled("h3")({
  "&:before": {
    position: "absolute",
    right: "-20px",
    top: "-15px",
    content: '"+"',
    fontSize: "30px",
    fontWeight: 400,
  },
  color: "white",
  fontWeight: 500,
  fontSize: "55px",
  lineHeight: 1,
  position: "relative",
  display: "inline-block",
});

const Caption = styled("p")({
  fontWeight: "700",
  fontSize: "15px",
  lineHeight: 1,
  textTransform: "uppercase",
  marginTop: "5px",
  color: "#FFF",
});

function index(props) {
  const { facts } = props;
  if (facts)
    return (
      <Wrapper>
        <Container>
          <Grid>
            {facts.map((fact, index) => (
              <Item key={`${fact.id}-${index}`}>
                <Icon>
                  <IMG src={fact.icon} />
                </Icon>
                <Header>{fact.count}</Header>
                <Caption>{fact.caption}</Caption>
              </Item>
            ))}
          </Grid>
        </Container>
      </Wrapper>
    );
}

export default index;
