import React from "react";
import { styled } from "@mui/material";
import { Search } from "@mui/icons-material";

const WidgetBox = styled("div")({
  border: "2px solid #ededed",
  boxSizing: "border-box",
  padding: "40px",
  marginBottom: "40px",
});

const WidgetTitle = styled("div")({
  marginBottom: "35px",
  position: "relative",
  paddingLeft: "45px",
  "&:before": {
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
    content: '""',
    height: "4px",
    width: "18px",
    borderRadius: "5px",
    position: "absolute",
    backgroundColor: "#af0420",
  },
  "&:after": {
    left: "22px",
    top: "50%",
    transform: "translateY(-50%)",
    content: '""',
    height: "4px",
    width: "4px",
    borderRadius: "5px",
    position: "absolute",
    backgroundColor: "#af0420",
  },
});

const Title = styled("h3")({});

const Searching = styled("div")({});

const Form = styled("form")({});

const SearchBox = styled("input")({
  backgroundColor: "#f8f8f8",
  fontSize: "15px",
  padding: "20px",
  width: "100%",
});

const Button = styled("button")({
  position: "absolute",
  right: 0,
  top: 0,
  width: "70px",
  fontSize: "18px",
  height: "100%",
  backgroundColor: "#af0420",
  color: "#fff",
  textAlign: "center",
  transition: "all 0.3s ease-in-out",
  cursor: "pointer",
  verticalAlign: "middle",
  // transition: "all 0.3s ease-in-out",
});

function index(props) {
  const { title, placeholder } = props;
  return (
    <WidgetBox>
      <WidgetTitle>
        <Title>{title}</Title>
      </WidgetTitle>
      <Searching>
        <Form action='#'>
          <SearchBox type='text' placeholder={placeholder} />
          <Button type='submit'>
            <Search />
          </Button>
        </Form>
      </Searching>
    </WidgetBox>
  );
}

export default index;
