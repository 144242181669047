import React from "react";
import { styled } from "@mui/material";
import { Description } from "../..";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

const WidgetBox = styled("div")({
  marginTop: "40px",
});

const Button = styled("a")({
  textDecoration: "none",
  fontWeight: 600,
  outline: "none !important",
  cursor: "pointer",
  fontSize: "17px",
  lineHeight: "32px",
  transition: "all 0.4s ease-in-out",
  position: "relative",
});

const Image = styled("img")({
  position: "relative",
  maxWidth: "100%",
  verticalAlign: "middle",
  borderStyle: "none",
  marginBottom: "20px",
  zIndex: 1,
});

const Social = styled("div")({
  marginTop: "30px",
});

const SocialLink = styled("a")({
  display: "inline-block",
  width: "36px",
  height: "36px",
  lineHeight: "39px",
  color: "inherit",
  margin: "0 4px",
  textAlign: "center",
  fontSize: "14px",
  textDecoration: "none",
  fontWeight: 600,
  outline: "none !important",
  cursor: "pointer",
  transition: "all 0.4s ease-in-out",
  position: "relative",
});

function index(props) {
  const { src } = props;
  return (
    <WidgetBox>
      <Button href='/our-academy'>
        <Image src={src} />
      </Button>
      <Description
        alignment='inherit'
        description='Sed ut perspiciatis unde omnis natus voluptatem accusa ntiumy doloremque laudantium.omnis natus voluptatem
        accusa ntiumy doloremque laudantium'
      />
      <Social>
        <SocialLink>
          <FacebookIcon />
        </SocialLink>
        <SocialLink>
          <TwitterIcon />
        </SocialLink>
        <SocialLink>
          <YouTubeIcon />
        </SocialLink>
      </Social>
    </WidgetBox>
  );
}

export default index;
