import { Container, Grid, styled } from "@mui/material";
import React from "react";
import pageBanner from "../../../assets/images/page-banner.jpg";
import { Breadcrumb } from "..";

const Wrapper = styled("div")({
  marginTop: "45px",
  backgroundColor: "rgba(17,17,17, .95)",
  paddingBottom: "160px",
  paddingTop: "140px",
  position: "relative",
  zIndex: 1,
  backgroundPosition: "450px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  "&:before": {
    position: "absolute",
    left: 0,
    content: '""',
    top: 0,
    background: "rgb(17,17,17)", // #af0420
    width: "100%",
    height: "100%",
    zIndex: -1,
    opacity: 0.6,
  },
});

const BannerText = styled("div")({
  position: "absolute",
  top: "70%",
  transform: "translateY(-50%) rotate(-90deg) translateX(23%)",
  WebkitTextFillColor: "transparent",
  WebkitTextStrokeColor: "rgba(255,255,255,0.2)",
  WebkitTextStrokeWidth: "4px",
  fontSize: "100px",
  fontWeight: 900,
  letterSpacing: "-0.03em",
  left: "110px",
  zIndex: 2,
  opacity: 0.15,
  textTransform: "uppercase",
});

const PageHeader = styled("h1")({
  color: "#fff",
  fontSize: "80px",
  fontWeight: 900,
  textTransform: "capitalize",
});

function index(props) {
  const { text, header, breadcrumbs, bgr } = props;
  return (
    <Wrapper
      style={{
        backgroundImage: `url(${bgr ? bgr : pageBanner})`,
      }}
    >
      <BannerText>{text}</BannerText>
      <Container>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <PageHeader>{header}</PageHeader>

            {breadcrumbs && <Breadcrumb breadcrumbs={breadcrumbs} />}
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default index;
