import React from "react";
import { styled } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const IconBox = styled("div")({
  borderColor: "#af0420",
  padding: 0,
  border: 0,
  paddingRight: "40px",
  marginTop: "30px",
});

const Icon = styled("div")({
  backgroundColor: "#e6f0fb",
  borderRadius: "7px",
  width: "60px",
  height: "60px",
  lineHeight: "60px",
  textAlign: "center",
  fontSize: "32px",
  float: "left",
  overflow: "hidden",
  marginRight: "25px",
});

const Content = styled("div")({
  overflow: "auto",
});

const Header = styled("h3")({
  marginTop: "-3px",
});

const Paragraph = styled("p")({
  marginTop: "5px",
});

function index(props) {
  const { title, description } = props;
  return (
    <IconBox>
      <Icon>
        <EmojiEventsIcon fontSize='large' color='success' style={{ verticalAlign: "middle" }} />
      </Icon>
      <Content>
        <Header>{title}</Header>
        <Paragraph>{description}</Paragraph>
      </Content>
    </IconBox>
  );
}

export default index;
