import React from "react";
import { styled } from "@mui/material";

const WidgetBox = styled("div")({
  border: "2px solid #ededed",
  boxSizing: "border-box",
  padding: "40px",
  marginBottom: "40px",
});

const WidgetTitle = styled("div")({
  marginBottom: "35px",
  position: "relative",
  paddingLeft: "45px",
  "&:before": {
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
    content: '""',
    height: "4px",
    width: "18px",
    borderRadius: "5px",
    position: "absolute",
    backgroundColor: "#af0420",
  },
  "&:after": {
    left: "22px",
    top: "50%",
    transform: "translateY(-50%)",
    content: '""',
    height: "4px",
    width: "4px",
    borderRadius: "5px",
    position: "absolute",
    backgroundColor: "#af0420",
  },
});

const Title = styled("h3")({});

const Tags = styled("div")({});

const Link = styled("a")({
  display: "inline-block",
  padding: "5px 10px",
  lineHeight: 1,
  fontWeight: 700,
  color: "#211e3b",
  background: "#f8f8f8",
  marginRight: "5px",
  marginBottom: "5px",
  transition: "all 0.4s ease-in-out",
});

function index(props) {
  const { title, tags } = props;
  return (
    <WidgetBox>
      <WidgetTitle>
        <Title>{title}</Title>
      </WidgetTitle>
      <Tags>
        {tags &&
          tags.map((tag, index) => (
            <Link
              key={`tag-${tag.id}${index}`}
              href={tag.url}
              style={{ fontSize: tag.value ? `${14 + tag.value}px` : "14px" }}
            >
              {tag.name}
            </Link>
          ))}
      </Tags>
    </WidgetBox>
  );
}

export default index;
