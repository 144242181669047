import React from 'react'

function index() {
    return (
        <div>
            {window.location.href}
            <a href="/">Back to Home</a>
        </div>
    )
}

export default index