import * as React from 'react';
import { useTranslation } from "react-i18next";
import { Container, Grid, Box, Typography, Select, Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';
import { Phone, Email, Facebook, Twitter } from '@mui/icons-material';
import AppBar from '@mui/material/AppBar';

const TopHeader = styled(AppBar)({
    position:'fixed',
    left: 0,
    zIndex: 999,
    transition: 'height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000)',
    backgroundColor: 'rgba(255, 255, 255, .95)',
    ['@media (min-width: 768px)']: {
        display: 'block',
    },
    '&:before': {
        position: 'absolute',
        content: '""',
        height: '45px',
        background: '#870319',
        width: '50%',// width: '714px',
        webkitClipPath: 'polygon(0 0, 97% 0, 100% 100%, 0% 100%)',
        clipPath: 'polygon(0 0, 97% 0, 100% 100%, 0% 100%)',
        top: 0,
        left: 0,
    }
});

const HeaderCta = styled('ul')({
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
    padding: 0,
    margin: 0,


});

const HeaderCtaItem = styled('li')({    
});

const HeaderCtaLink = styled('a')({
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '16px',
    textDecoration: 'none',
    transition: 'all 0.4s ease-in-out',
    '&:hover': {
        color: '#000',
    },
});

const HeaderRightCta = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
});

const SocialProfile = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const SocialProfileLink = styled('a')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '45px',
    height: '45px',
    marginRight: '5px',
    color: '#444',
    textDecoration: 'none',
    transition: 'all 0.3s ease',
    '&:hover': {
        color: '#870319',
    },
});

const SupportEmailLink = () => (
    <HeaderCtaLink href="mailto:support@tangoatolyesi.com">
        <Email sx={{ marginRight: '10px', height: '20px' }} />
        support@tangoatolyesi.com
    </HeaderCtaLink>
);

const PhoneLink = () => (
    <HeaderCtaLink href="tel:+905519412361">
        <Phone sx={{ marginRight: '10px', height: '20px' }} />
        +90 551 941 23 61
    </HeaderCtaLink>
);

const Topbar = () => {
    const { t, i18n } = useTranslation();

    const [anchorEls, setAnchorEls] = React.useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [selectedLanguage, setSelectedLanguage] = React.useState('en');

    const handleMenuOpen = (event, index) => {
        // setAnchorEl(event.currentTarget);
        const newAnchorEls = [...anchorEls];
        newAnchorEls[index] = event.currentTarget;
        setAnchorEls(newAnchorEls);
        setSelectedIndex(index);
    };

    const handleMenuClose = (index) => {
        // setAnchorEl(null);
        const newAnchorEls = [...anchorEls];
        newAnchorEls[index] = null;
        setAnchorEls(newAnchorEls);
        setSelectedIndex(-1);
    };
    const handleLanguageChange = (lng) => {
        setSelectedLanguage(lng);
        i18n.changeLanguage(lng);
        handleMenuClose(999); // close the language selection menu id :D
    };

    return (
        <>
        <TopHeader>
            <Container>
                <Grid container>
                    <Grid item md={7} xs={12} sx={{
                        paddingRight: { md: 0 },
                        zIndex: 1,
                    }}>
                        <Box sx={{
                            display: 'flex', alignItems: 'center', height: '100%'
                        }}>
                            <HeaderCta>
                                <HeaderCtaItem>
                                    <SupportEmailLink />
                                </HeaderCtaItem>
                                <span style={{ color: '#fff', marginInline: "20px", fontWeight: 'bold' }}>|</span>
                                <HeaderCtaItem>
                                    <PhoneLink />
                                </HeaderCtaItem>
                            </HeaderCta>
                        </Box>
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <HeaderRightCta>
                            <SocialProfile>
                                <SocialProfileLink href="#">
                                    <Facebook />
                                </SocialProfileLink>
                                <SocialProfileLink href="#">
                                    <Twitter />
                                </SocialProfileLink>
                            </SocialProfile>
                            <span style={{ color: '#870319', marginInline: "20px", fontWeight: 'bold'}}>|</span>
                            <Button color="inherit"
                                onClick={(event) => handleMenuOpen(event, 999)}
                                disableElevation
                                endIcon={<KeyboardArrowDownIcon />}>
                                {selectedLanguage === 'en' ? <img src={require('../../assets/images/flags/united-states.png')} style={{ maxWidth: 24, marginRight: 5 }} alt="English" /> : <img src={require('../../assets/images/flags/turkey.png')} style={{ maxWidth: 24, marginRight: 5 }} alt="Turkish" />}
                            </Button>
                            <Menu
                                anchorEl={anchorEls[999]}
                                open={selectedIndex === 999}
                                onClose={() => handleMenuClose(999)}
                            >
                                <MenuItem value="en" onClick={() => handleLanguageChange('en')}>
                                    <img src={require('../../assets/images/flags/united-states.png')} style={{ maxWidth: 24, marginRight: 5 }} alt="English" />
                                    English
                                </MenuItem>
                                <MenuItem value="tr" onClick={() => handleLanguageChange('tr')}>
                                    <img src={require('../../assets/images/flags/turkey.png')} style={{ maxWidth: 24, marginRight: 5 }} alt="Turkish" />
                                    Turkish
                                </MenuItem>
                            </Menu>
                            {/* <LanSelect>
                                <Select
                                    labelId="lan-select-label"
                                    id="lan-select"
                                    value="English" */}
                        </HeaderRightCta>
                    </Grid>
                </Grid>
            </Container>
        </TopHeader>
        </>
    )
}

export default Topbar;
