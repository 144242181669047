import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";

import ScrollUp from "../../components/ScrollUp";
import ChatBox from "../../components/ChatBox";
import Footer from "../../components/Section/Footer";

import bgr from "../../assets/images/tango-performer-banner.png";
import About from "../../components/Section/About";
import Calendar from "../../components/Section/Calendar";
import { Fact, PageBanner } from "../../components/Elements";
import { useTranslation } from "react-i18next";
import Team from "../../components/Section/Team";
import FAQ from "../../components/Section/FAQ";

function TangoPerformers() {
  const { t } = useTranslation();
  const members = [
    {
      id: 1,
      name: "hassan gogani",
      position: "founder & tango master",
      photo: require("../../assets/images/team1.png"),
      facebook: "#",
      instagram: "#",
      youtube: "#",
    },
    {
      id: 2,
      name: "mina türk",
      position: "founder & teacher",
      photo: require("../../assets/images/team2.png"),
      facebook: "#",
      instagram: "#",
    },
    {
      id: 3,
      name: "arham eskafi",
      position: "tech guy",
      photo: require("../../assets/images/team4.png"),
      facebook: "#",
      twitter: "#",
      instagram: "#",
    },
    {
      id: 4,
      name: "parisa khaleghi",
      position: "teacher assistant",
      photo: require("../../assets/images/team3.png"),
      facebook: "#",
      instagram: "#",
    },
  ];

  const facts = [
    {
      id: 1,
      count: 743,
      icon: require("../../assets/images/in-studio.png"),
      caption: "trained dancers",
    },
    {
      id: 2,
      count: 30,
      icon: require("../../assets/images/milonga.png"),
      caption: "cozy tango events",
    },
    {
      id: 3,
      count: 10,
      icon: require("../../assets/images/salsa.png"),
      caption: "short sequences",
    },
    {
      id: 4,
      count: 45,
      icon: require("../../assets/images/wedding-dance.png"),
      caption: "wedding dance lessons",
    },
  ];

  const data = [
    {
      id: 1,
      summary: t("question1"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer1") }} />,
    },
    {
      id: 2,
      summary: t("question2"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer2") }} />,
    },
    {
      id: 3,
      summary: t("question3"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer3") }} />,
    },
    {
      id: 4,
      summary: t("question4"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer4") }} />,
    },
    {
      id: 5,
      summary: t("question5"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer5") }} />,
    },
    {
      id: 6,
      summary: t("question6"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer6") }} />,
    },
    {
      id: 7,
      summary: t("question7"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer7") }} />,
    },
    {
      id: 8,
      summary: t("question8"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer8") }} />,
    },
  ];
  return (
    <Fragment>
      <ChatBox />
      <Navbar />
      <PageBanner
        text='Performers'
        header='Tango Performers Hire'
        breadcrumbs={[
          { label: "Home", url: "/" },
          { label: "Wedding", url: "#" },
          { label: "Tango Performers Hire", url: "#" },
        ]}
        bgr={bgr}
      />

      <About
        short={true}
        title='Impress your guests with'
        subtitle='tango performers!'
        description='Argentine tango is the perfect choice to entertain, amaze and inspire your guests: in turn intimate, passionate, exciting to dance and watch, sensual, fiery and playful – No doubt tango will leave lasting memories!'
      />

      {/* <Team
        title={"Our Professional Dancers"}
        header={"Meet Our Dancers"}
        members={members}
        allMemberURL={"#"}
      />
      <FAQ
        title={"You've Got Questions???"}
        header={"Let’s See If We’ve Got Answers."}
        dataset={data}
      /> */}
      <ScrollUp />
      <Footer />
    </Fragment>
  );
}

export default TangoPerformers;
