import React from "react";
import { Grid, styled } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";

const SinglePricingPlan = styled("div")({
  marginTop: 0,
  padding: "50px 45px",
  borderWidth: "2px",
  borderStyle: "solid",
  borderRadius: "7px",
  transition: "all 0.3s ease-in-out",
  position: "relative",
  backgroundColor: "#fff",
  borderColor: "#fff",
  zIndex: 1,
  "& span": {
    fontSize: "12px",
  },
});

const Pricing = styled("div")({
  marginTop: "35px",
  position: "relative",
  boxSizing: "border-box",
  display: "block",
  "& span": {
    position: "absolute",
    fontSize: "18px",
    top: 0,
  },
  "& h2": {
    display: "inline-block",
    fontSize: "42px",
    lineHeight: 1,
    paddingLeft: "18px",
    letterSpacing: -1,
  },
  "& p": {
    marginLeft: "5px",
    textTransform: "lowercase",
    display: "inline-block",
    fontWeight: 400,
    transition: "all 0.3s ease-in-out",
  },
});

const PlanFeatures = styled("ul")({
  marginTop: "30px",
  "& li": {
    position: "relative",
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
  },
});

const Button = styled("a")({});

function index(props) {
  const { priceList } = props;
  return (
    <Grid container spacing={2}>
      {priceList &&
        priceList.map((item, index) => (
          <Grid item md={6} sx={{ mt: { xs: "1.5rem", xl: 0 } }} key={`${item.id}-${index}`}>
            <SinglePricingPlan
              style={
                index % 2 == 0
                  ? {
                      backgroundColor: "rgb(175, 4, 32)",
                      borderColor: "rgb(175, 4, 32)",
                      color: "#fff",
                    }
                  : {}
              }
            >
              <h3>{item.header}</h3>
              <p>{item.title}</p>
              <span>{item.description}</span>
              <Pricing>
                <span>{item.price.currency}</span>
                <h2>{item.price.value}</h2>
                <p>{item.price.info}</p>
              </Pricing>
              <PlanFeatures>
                {item.features.map((feature, index) => (
                  <li class='active' key={`${feature.id}-${index}`}>
                    <CheckIcon style={{ verticalAlign: "middle", marginRight: 10 }} color='white' />
                    {feature.caption}
                  </li>
                ))}
              </PlanFeatures>
              <div class='buy-btn'>
                <Button
                  href={item.url}
                  style={
                    index % 2 == 0
                      ? {
                          color: "rgb(175, 4, 32)",
                          padding: "20px 40px",
                          borderRadius: "5px",
                          fontSize: "14px",
                          backgroundColor: "#fff",
                          border: "1px solid inherit",
                          boxSizing: "border-box",
                          lineHeight: 1,
                          display: "inline-block",
                          textTransform: "uppercase",
                          transition: "all 0.4s ease-in-out",
                          marginTop: "30px",
                          fontWeight: 900,
                        }
                      : {
                          color: "#000",
                          padding: "20px 40px",
                          borderRadius: "5px",
                          fontSize: "14px",
                          backgroundColor: "#fff",
                          border: "1px solid #000",
                          boxSizing: "border-box",
                          lineHeight: 1,
                          display: "inline-block",
                          textTransform: "uppercase",
                          transition: "all 0.4s ease-in-out",
                          marginTop: "30px",
                          fontWeight: 900,
                        }
                  }
                >
                  {`${item.caption} `}
                  <ArrowForwardIcon style={{ verticalAlign: "middle" }} color='white' />
                </Button>
              </div>
            </SinglePricingPlan>
          </Grid>
        ))}
    </Grid>
  );
}

export default index;
