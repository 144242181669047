import React from "react";
import { styled } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const WidgetBox = styled("div")({
  marginTop: "40px",
});

const WidgetTitle = styled("div")({
  marginBottom: "30px",
});

const Title = styled("h4")({
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "28px",
});

const LinksList = styled("ul")({
  listStyleType: "none",
  margin: 0,
  padding: 0,
});

const Link = styled("li")({
  display: "flex",
  marginBottom: "15px",
  fontWeight: 500,
  alignItems: "center",
});

const Button = styled("a")({
  color: "inherit",
  textDecoration: "none",
  fontWeight: 600,
  outline: "none !important",
  cursor: "pointer",
  fontSize: "17px",
  lineHeight: "32px",
  transition: "all 0.4s ease-in-out",
  position: "relative",
});

function index(props) {
  const { header, links } = props;
  return (
    <WidgetBox>
      <WidgetTitle>
        <Title>{header}</Title>
      </WidgetTitle>
      <LinksList>
        {links
          ? links.map((item, index) => (
              <Link key={`${item.id}-${index}`}>
                <KeyboardDoubleArrowRightIcon fontSize='small' sx={{ marginRight: "5px" }} />
                <Button href={item.url}>{item.caption}</Button>
              </Link>
            ))
          : null}
      </LinksList>
    </WidgetBox>
  );
}

export default index;
