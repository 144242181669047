import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "./index.module.css";

import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
// import MenuIcon from '@mui/icons-material/Menu';
import { Container, Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Fade from "@mui/material/Fade";
import TopBar from "../Topbar";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const NavigationBar = styled(AppBar)({
  position: "fixed",
  top: "45px",
  left: 0,
  textAlign: "left",
  background: "rgba(17,17,17, .95)",
  // boxShadow: 'none',
  //     zIndex: 201,
  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
  zIndex: 999,
  transition: "height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000)",
});

const Logo = styled("div")({
  flexGrow: 1,
});

const ReserveButton = styled(Button)({
  transition: "background-color 170ms ease-out",
  boxShadow: "0 3px 0 0 #870319",
  backgroundColor: "#af0420",
  color: "white",
  marginLeft: "10px",
  borderRadius: "10px",
  paddingX: "20px",
  "&:hover": {
    backgroundColor: "red",
    borderColor: "red",
    boxShadow: "0px 3px 0 0 #870319",
  },
});

const CartButton = styled(Button)({
  transition: "background-color 170ms ease-out",
  boxShadow: "0 3px 0 0 #870319",
  backgroundColor: "#af0420",
  color: "white",
  marginLeft: "10px",
  borderRadius: "10px",
  paddingX: "20px",
  "&:hover": {
    // backgroundColor: 'red',
    // borderColor: 'red',
    boxShadow: "0px 3px 0 0 #870319",
  },
});

function Navbar() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [anchorEls, setAnchorEls] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  // const [selectedLanguage, setSelectedLanguage] = React.useState('en');

  const handleMenuOpen = (event, index) => {
    // setAnchorEl(event.currentTarget);
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
    setSelectedIndex(index);
  };

  const handleMenuClose = (index, path) => {
    if (path) navigate(path);
    // setAnchorEl(null);
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
    setSelectedIndex(-1);
  };

  // const handleLanguageChange = (lng) => {
  //     setSelectedLanguage(lng);
  //     i18n.changeLanguage(lng);
  //     handleMenuClose(999); // close the language selection menu id :D
  // };

  return (
    <>
      {/* <div style={{ position: 'static', width: '100%', }}> */}
      <TopBar />
      <NavigationBar>
        <Container>
          <Grid container>
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                // padding: 0,
                // margin: 0,
                // paddingRight: { md: 0 },
                zIndex: 1,
              }}
            >
              <Toolbar sx={{ padding: "0 !important" }}>
                <Logo
                  sx={{ flexGrow: 1, cursor: "pointer" }}
                  onClick={() => handleMenuClose(0, "/")}
                >
                  <img
                    src={require("../../assets/brand/logo.png")}
                    style={{ maxHeight: "36px" }}
                    alt='Tango Atölyesi'
                  />
                </Logo>
                {/* <Title variant="h6">
                                Tango Atolyesi
                            </Title> */}
                {/* About */}
                <Button
                  color='inherit'
                  id='fade-button'
                  aria-controls={selectedIndex === 0 ? "fade-menu" : undefined}
                  aria-haspopup='true'
                  aria-expanded={selectedIndex === 0 ? "true" : undefined}
                  onClick={(event) => handleMenuOpen(event, 0)}
                >
                  {t("about")}
                </Button>
                <Menu
                  id='fade-menu'
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEls[0]}
                  open={selectedIndex === 0}
                  onClose={() => handleMenuClose(0)}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={() => handleMenuClose(0, "/blog/argentine-tango")}>
                    {t("argentine-tango")}
                  </MenuItem>
                  <hr />
                  <MenuItem onClick={() => handleMenuClose(0, "/our-academy")}>
                    {t("our-academy")}
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuClose(0, "/our-academy/#our-instructors")}>
                    {t("our-instructors")}
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuClose(0, "/our-academy/#faqs")}>
                    {t("faqs")}
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuClose(0, "/our-academy/#reviews")}>
                    {t("reviews")}
                  </MenuItem>
                  <hr />
                  <MenuItem onClick={() => handleMenuClose(0, "/gallery")}>
                    {t("Photo Gallery")}
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuClose(0, "/blog")}>{t("blog")}</MenuItem>
                  <MenuItem onClick={() => handleMenuClose(0, "/music-lyrics")}>
                    {t("music-lyrics")}
                  </MenuItem>
                </Menu>

                {/* In-Person-Courses */}
                <Button
                  color='inherit'
                  id='fade-button'
                  aria-controls={selectedIndex === 1 ? "fade-menu" : undefined}
                  aria-haspopup='true'
                  aria-expanded={selectedIndex === 1 ? "true" : undefined}
                  onClick={(event) => handleMenuOpen(event, 1)}
                >
                  {t("in-person-courses")}
                </Button>
                <Menu
                  id='fade-menu'
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEls[1]}
                  open={selectedIndex === 1}
                  onClose={() => handleMenuClose(1)}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={() => handleMenuClose(1, "/class-schedule")}>
                    {t("schedule")}
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuClose(1, "/beginner")}>
                    {t("beginner-course")}
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuClose(1, "/pre-intermediate")}>
                    {t("pre-intermediate-course")}
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuClose(1, "/intermediate")}>
                    {t("intermediate-course")}
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuClose(1, "/pre-advanced")}>
                    {t("pre-advanced-course")}
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuClose(1, "/advanced")}>
                    {t("advanced")}
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuClose(1, "/short-sequences")}>
                    {t("short-sequences")}
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuClose(1, "/classy-heels")}>
                    {t("classy-heels")}
                  </MenuItem>
                </Menu>
                {/* Parctica */}
                <Button
                  color='inherit'
                  id='fade-button'
                  aria-controls={selectedIndex === 2 ? "fade-menu" : undefined}
                  aria-haspopup='true'
                  aria-expanded={selectedIndex === 2 ? "true" : undefined}
                  onClick={(event) => handleMenuOpen(event, 2)}
                >
                  {t("practica")}
                </Button>
                <Menu
                  id='fade-menu'
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEls[2]}
                  open={selectedIndex === 2}
                  onClose={() => handleMenuClose(2)}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={() => handleMenuClose(2, "/practica")}>
                    {t("practica")}
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuClose(2, "/short-sequences")}>
                    {t("short-sequences")}
                  </MenuItem>
                </Menu>

                {/* Classes & Milonga */}
                <Button
                  color='inherit'
                  id='fade-button'
                  aria-controls={selectedIndex === 3 ? "fade-menu" : undefined}
                  aria-haspopup='true'
                  aria-expanded={selectedIndex === 3 ? "true" : undefined}
                  onClick={(event) => handleMenuOpen(event, 3)}
                >
                  {t("classes-milonga")}
                </Button>
                <Menu
                  id='fade-menu'
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEls[3]}
                  open={selectedIndex === 3}
                  onClose={() => handleMenuClose(3)}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={() => handleMenuClose(3, "/weekly-class")}>
                    {t("weekly-class")}
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuClose(3, "/classy-heels")}>
                    {t("classy-heels")}
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuClose(3, "/private-lessons")}>
                    {t("private-lessons")}
                  </MenuItem>
                </Menu>

                {/* Wedding */}
                <Button
                  color='inherit'
                  id='fade-button'
                  aria-controls={selectedIndex === 4 ? "fade-menu" : undefined}
                  aria-haspopup='true'
                  aria-expanded={selectedIndex === 4 ? "true" : undefined}
                  onClick={(event) => handleMenuOpen(event, 4)}
                >
                  {t("wedding")}
                </Button>
                <Menu
                  id='fade-menu'
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEls[4]}
                  open={selectedIndex === 4}
                  onClose={() => handleMenuClose(4)}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={() => handleMenuClose(4, "/wedding-dance")}>
                    {t("wedding-dance-lessons")}
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuClose(4, "/tango-performers")}>
                    {t("tango-performers-hire")}
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuClose(4, "/wedding-package")}>
                    {t("wedding-lesson-package")}
                  </MenuItem>
                </Menu>

                <Button color='inherit' href='/contact-us'>
                  {t("contact")}
                </Button>

                <ReserveButton variant='contained' color='error'>
                  {t("reserve-now")}!
                </ReserveButton>
                {/* <CartButton variant="contained" color="error" >{''}<ShoppingCartIcon fontSize='small' style={{verticalAlign:'middle'}} /></CartButton> */}
              </Toolbar>
            </Grid>
          </Grid>
        </Container>
      </NavigationBar>
      {/* </div> */}
    </>
  );
}

export default Navbar;
