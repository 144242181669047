import { Container, Grid, styled } from '@mui/material'
import React from 'react'
import schedule from '../../../assets/images/calendar.png'
import { useTranslation } from "react-i18next";

import VisibilityIcon from '@mui/icons-material/Visibility';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import NightlifeIcon from '@mui/icons-material/Nightlife';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import MoodIcon from '@mui/icons-material/Mood';

const Section = styled('section')({
    position: 'relative',
    backgroundColor: '#eef4fc',
    padding: '130px 0px',
    paddingTop: '35px',
})

const BlogPosts = styled('div')({
    margin: 0,
    padding: 0,
})

const Post = styled('div')({
    position: 'relative',
    marginBottom: '40px',
})
const FeaturedThumb = styled('div')({
    position: 'relative',
    height: '550px',
    width: 'auto',
    content: '""',
    borderRadius: '30px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
})

const Content = styled('div')({
    padding: '50px',
    border: '2px solid #ededed',
    borderTop: 0,
    overflow: 'hidden',
    '& h2': {
        fontSize: '36px',
        lineHeight: '130%',
        letterSpacing: '-3px',
    },
    '& p': {
        marginTop: '15px',
    }
})

const Meta = styled('div')({
    marginTop: '10px',
    '& span': {
        color: '#202426',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: 1,
        display: 'inline-block',
        marginRight: '25px',
    }
})

const Info = styled('div')({
    marginTop: '30px',
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
})

const Author = styled('div')({
    overflow: 'hidden',
    display: 'block',
    flex: 1,
})

const AuthorPhoto = styled('div')({
    height: '40px',
    width: '40px',
    lineHeight: '40px',
    borderRadius: '50%',
    background: '#f2f2f2',
    marginRight: '15px',
    float: 'left',
    overflow: 'hidden',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
})

const AuthorName = styled('h5')({
    display: 'inline-block',
    fontSize: '16px',
    lineHeight: 1,
    marginTop: '9px',
})

const PostLink = styled('div')({
    fontWeight: 700,
    color: '#211e3b',
    lineHeight: '32px',
    fontSize: '17px',
})

function Calendar() {
    const { t, i18n } = useTranslation();

    return (
        <Section>
            <Container>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12} sx={{ pr: { xs: 5 } }}>
                        <div class="section-title mb-30">
                            <p>All Levels</p>
                            <h1>Class Schedule</h1>
                        </div>

                        <p class="pr-lg-5">Sed ut perspiciatis unde omnis natus error sit voluptatem accusa ntium doloremque laudantium totam.</p>

                        <div style={{
                            borderColor: '#af0420',
                            padding: 0,
                            border: 0,
                            paddingRight: '40px',
                            marginTop: '30px',
                        }}>
                            <div style={{
                                backgroundColor: '#e6f0fb',
                                borderRadius: '7px',
                                width: '60px',
                                height: '60px',
                                lineHeight: '60px',
                                textAlign: 'center',
                                fontSize: '32px',
                                float: 'left',
                                overflow: 'hidden',
                                marginRight: '25px',
                            }}>
                                <i class="fal fa-users"></i>
                            </div>
                            <div style={{ overflow: 'auto' }}>
                                <h3 style={{
                                    marginTop: '-3px',
                                }}>What to wear?</h3>
                                <p style={{
                                    marginTop: '5px',
                                }}>Please wear comfortable clothes that allows you to move your legs freely. No pencil skirts or super skinny jeans!</p>
                            </div>
                        </div>
                        <div style={{
                            borderColor: '#af0420',
                            padding: 0,
                            border: 0,
                            paddingRight: '40px',
                            marginTop: '30px',
                        }}>
                            <div style={{
                                backgroundColor: '#e6f0fb',
                                borderRadius: '7px',
                                width: '60px',
                                height: '60px',
                                lineHeight: '60px',
                                textAlign: 'center',
                                fontSize: '32px',
                                float: 'left',
                                overflow: 'hidden',
                                marginRight: '25px',
                            }}>
                                <i class="fal fa-users"></i>
                            </div>
                            <div style={{ overflow: 'auto' }}>
                                <h3 style={{
                                    marginTop: '-3px',
                                }}>How about shoes?</h3>
                                <p style={{
                                    marginTop: '5px',
                                }}>Please wear low-heeled, comfortable shoes with a closed toe. If you already have some type of dance shoes; (character shoes, ballroom shoes etc.) those are fine to wear! Leather soles are preferable, but you can also get by in sneakers with a hard rubber sole like Puma, Converse or Tom's shoes. If you have no suitable shoes, socks will do just fine!</p>
                            </div>
                        </div>

                    </Grid>

                    {/* class="col-lg-6 pl-lg-5 mt-5 mt-lg-0 col-12"  */}
                    <Grid item lg={6} xs={12}>
                        <div class="about-right-img">
                            <span class="dot-circle"></span>

                            <div
                                style={{
                                    position: 'relative',
                                    borderRadius: '7px',
                                    // backgroundColor: '#eee',
                                    height: '550px',
                                    width: '570px',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    backgroundImage: `url(${schedule})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    backgroundColor: '#fff',
                                    WebkitBoxShadow: '0px 10px 60px 0px rgba(200, 226, 255, 0.45)',
                                    boxShadow: '0px 10px 60px 0px rgba(200, 226, 255, 0.45)',

                                }}>
                            </div>
                            <span class="triangle-bottom-right"></span>
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{
                    paddingTop: '100px',
                }}>
                    <Grid item lg={4} md={6} xs={12}>
                        <div style={{
                            borderRadius: '7px',
                            backgroundColor: '#fff',
                            WebkitBoxShadow: '0px 10px 60px 0px rgba(200, 226, 255, 0.45)',
                            boxShadow: '0px 10px 60px 0px rgba(200, 226, 255, 0.45)',
                            overflow: 'hidden',
                            marginTop: '30px',
                            padding: '30px 35px',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <div style={{
                                fontSize: '60px',
                                lineHeight: 1,
                                color: '#086ad7',
                                float: 'left',
                                overflow: 'hidden',
                                marginRight: '25px'
                            }}>
                                <MoodIcon fontSize='large' />
                            </div>
                            <div style={{ overflow: 'auto' }}>
                                <h3 style={{ transition: 'all 0.3s ease-in-out' }}>Prerequisite</h3>
                                <p>no experience required</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                        <div style={{
                            borderRadius: '7px',
                            backgroundColor: '#fff',
                            WebkitBoxShadow: '0px 10px 60px 0px rgba(200, 226, 255, 0.45)',
                            boxShadow: '0px 10px 60px 0px rgba(200, 226, 255, 0.45)',
                            overflow: 'hidden',
                            marginTop: '30px',
                            padding: '30px 35px',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <div style={{
                                fontSize: '60px',
                                lineHeight: 1,
                                color: '#086ad7',
                                float: 'left',
                                overflow: 'hidden',
                                marginRight: '25px'
                            }}>
                                <PersonPinIcon fontSize='large'/>
                            </div>
                            <div style={{ overflow: 'auto' }}>
                                <h3 style={{ transition: 'all 0.3s ease-in-out' }}>Partner</h3>
                                <p>no partner needed</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                        <div style={{
                            borderRadius: '7px',
                            backgroundColor: '#fff',
                            WebkitBoxShadow: '0px 10px 60px 0px rgba(200, 226, 255, 0.45)',
                            boxShadow: '0px 10px 60px 0px rgba(200, 226, 255, 0.45)',
                            overflow: 'hidden',
                            marginTop: '30px',
                            padding: '30px 35px',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <div style={{
                                fontSize: '60px',
                                lineHeight: 1,
                                color: '#086ad7',
                                float: 'left',
                                overflow: 'hidden',
                                marginRight: '25px'
                            }}>
                                {/* <i class="flaticon-unlock"></i> */}
                                <NightlifeIcon fontSize='large'/>
                            </div>
                            <div style={{ overflow: 'auto' }}>
                                <h3 style={{ transition: 'all 0.3s ease-in-out' }}>Assets</h3>
                                <p>Be relax and enjoy the class</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Section>        
        )
}

export default Calendar