import React from "react";
import { Typography, styled } from "@mui/material";

const Description = styled(Typography)({
  pr: { lg: 5 },
});

function index(props) {
  const { description, alignment } = props;
  return <Description style={{ textAlign: alignment ? alignment : "justify" }}>{description}</Description>;
}

export default index;
