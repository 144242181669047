import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import { PageBanner } from "../../components/Elements";
import ChatBox from "../../components/ChatBox";
import Blog from "../../components/Blog";

import ScrollUp from "../../components/ScrollUp";
import Footer from "../../components/Section/Footer";

import banner from "../../assets/images/blog-banner.jpg";

function index() {
  return (
    <Fragment>
      <ChatBox />
      <Navbar />
      <PageBanner
        text='Blog'
        header='News feed'
        breadcrumbs={[
          { label: "Home", url: "/" },
          { label: "About", url: "/our-academy" },
          { label: "News & Blog", url: "/blog" },
        ]}
        bgr={banner}
      />

      <Blog />
      <ScrollUp />
      <Footer />
    </Fragment>
  );
}

export default index;
