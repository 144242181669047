import React from "react";
import { styled } from "@mui/material";

const WidgetBox = styled("div")({
  border: "2px solid #ededed",
  boxSizing: "border-box",
  padding: "40px",
  marginBottom: "40px",
});

const WidgetTitle = styled("div")({
  marginBottom: "35px",
  position: "relative",
  paddingLeft: "45px",
  "&:before": {
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
    content: '""',
    height: "4px",
    width: "18px",
    borderRadius: "5px",
    position: "absolute",
    backgroundColor: "#af0420",
  },
  "&:after": {
    left: "22px",
    top: "50%",
    transform: "translateY(-50%)",
    content: '""',
    height: "4px",
    width: "4px",
    borderRadius: "5px",
    position: "absolute",
    backgroundColor: "#af0420",
  },
});

const Title = styled("h3")({});

const Categories = styled("div")({});

const List = styled("ul")({});

const Category = styled("li")({
  color: "#211e3b",
});

const Link = styled("a")({
  color: "inherit",
  position: "relative",
  background: "#f8f8f8",
  padding: "20px 30px",
  lineHeight: 1,
  fontSize: "14px",
  fontWeight: 700,
  display: "block",
  transition: "all 0.3s ease-in-out",
  marginBottom: "10px",
  "&:hover": {
    color: "#ff5e14",
  },
});

const Number = styled("span")({
  position: "absolute",
  width: "60px",
  lineHeight: "53px",
  height: "100%",
  content: '""',
  backgroundColor: "#e3e3e3",
  right: 0,
  top: 0,
  textAlign: "center",
});

function index(props) {
  const { title, categories } = props;
  return (
    <WidgetBox>
      <WidgetTitle>
        <Title>{title}</Title>
      </WidgetTitle>
      <Categories>
        <List>
          {categories &&
            categories.map((category, index) => (
              <Category key={`category-${category.id}${index}`}>
                <Link href={category.url}>
                  {category.name} <Number>{category.count}</Number>
                </Link>
              </Category>
            ))}
        </List>
      </Categories>
    </WidgetBox>
  );
}

export default index;
