import React from "react";
import { styled } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const WidgetBox = styled("div")({
  marginTop: "40px",
});

const WidgetTitle = styled("div")({
  marginBottom: "30px",
});

const Title = styled("h4")({
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "28px",
});

const RecentPostList = styled("div")({
  margin: 0,
  padding: 0,
});

const Post = styled("div")({
  overflow: "hidden",
  boxSizing: "border-box",
  marginBottom: "25px",
});

const Thumbnail = styled("div")({
  width: "80px",
  height: "80px",
  backgroundColor: "#eee",
  overflow: "hidden",
  float: "left",
  marginRight: "20px",
  borderRadius: "7px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  position: "relative",
  backgroundPosition: "center",
});

const Details = styled("div")({});

const Date = styled("span")({
  display: "flex",
  fontSize: "14px",
  lineHeight: 1,
  verticalAlign: "middle",
  alignItems: "center",
});

const Button = styled("a")({
  color: "inherit",
  textDecoration: "none",
  fontWeight: 600,
  outline: "none !important",
  cursor: "pointer",
  fontSize: "17px",
  lineHeight: "32px",
  transition: "all 0.4s ease-in-out",
  position: "relative",
});

const Caption = styled("h5")({
  letterSpacing: "-1px",
  fontSize: "18px",
  lineHeight: "26px",
  fontWeight: 900,
});

function index(props) {
  const { header, posts } = props;
  return (
    <WidgetBox>
      <WidgetTitle>
        <Title>{header}</Title>
      </WidgetTitle>
      <RecentPostList>
        {posts
          ? posts.map((post, index) => (
              <Post key={`${post.id}-${index}`}>
                <Thumbnail style={{ backgroundImage: `url(${post.thumbnail})` }} />
                <Details>
                  <Date>
                    <CalendarMonthIcon fontSize='small' sx={{ marginRight: "10px" }} />
                    {post.date}
                  </Date>

                  <Button href={post.url}>
                    <Caption>{post.title}</Caption>
                  </Button>
                </Details>
              </Post>
            ))
          : null}
      </RecentPostList>
    </WidgetBox>
  );
}

export default index;
