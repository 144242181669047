import React from "react";
import { Container, Grid, styled, useTheme } from "@mui/material";
import { LinksWidget, RecentPostsWidget, SiteInfoWidget } from "../../Elements";

const Wrapper = styled("footer")({
  poistion: "relative",
  backgroundColor: "rgba(17,17,17, .95)",
  zIndex: 999,
});

const Widgets = styled("div")({
  paddingTop: "55px",
  paddingBottom: "100px",
});

const Copyright = styled("div")({
  margin: 0,
  padding: 0,
  color: "white",
});

const CopyrightContent = styled("div")({
  borderTop: "1px solid #efeff1",
  padding: "30px 0",
  textAlign: "center",
});

const Button = styled("a")({
  color: "inherit",
  textDecoration: "none",
  cursor: "pointer",
  fontSize: "16px",
  lineHeight: "32px",
  fontWeight: 900,
  fontStyle: "italic",
  transition: "all 0.4s ease-in-out",
});

function Footer() {
  const theme = useTheme();
  const posts = [
    {
      id: 1,
      date: "15th July 2021",
      title: "Argentine Tango",
      url: "/blog/argentine-tango",
      thumbnail: require("../../../assets/images/class.jpg"),
    },
    {
      id: 2,
      date: "20th June 2021",
      title: "Tango for everyone",
      url: "/blog/tango-for-everyone",
      thumbnail: require("../../../assets/images/cozy-event.jpg"),
    },
  ];
  const links1 = [
    {
      id: 1,
      caption: "Our Story",
      url: "/our-academy",
    },
    {
      id: 2,
      caption: "FAQ",
      url: "/our-academy/#faq",
    },
    {
      id: 3,
      caption: "Shipping",
      url: "/#",
    },
    {
      id: 4,
      caption: "Returns",
      url: "/#",
    },
    {
      id: 5,
      caption: "Customer Service",
      url: "/#",
    },
    {
      id: 6,
      caption: "Contact Us",
      url: "/contact-us",
    },
  ];
  const links2 = [
    {
      id: 1,
      caption: "Shop",
      url: "/#",
    },
    {
      id: 2,
      caption: "News & Blog",
      url: "/blog",
    },
    {
      id: 3,
      caption: "Weekly Class & Milongas",
      url: "/weekly-class",
    },
    {
      id: 4,
      caption: "Private Lessons",
      url: "/private-lessons",
    },
    {
      id: 5,
      caption: "Wedding Dance Lessons",
      url: "/wedding-dance",
    },
  ];

  return (
    <Wrapper>
      <Widgets>
        <Container>
          <Grid sx={{ width: "100%", display: "flex", flex: 1, flexDirection: "row", color: "white" }}>
            <Grid
              item
              md={6}
              xl={3}
              xs={12}
              sx={{
                flex: 1,
                [theme.breakpoints.up("xl")]: {
                  pr: 4,
                },
              }}
            >
              <SiteInfoWidget src={require("../../../assets/brand/logo.png")} />
            </Grid>
            <Grid
              md={6}
              xl={2}
              xs={12}
              style={{
                flex: 1,
              }}
            >
              <LinksWidget header='Help & Information' links={links1} />
            </Grid>
            <Grid
              md={6}
              xl={2}
              xs={12}
              style={{
                flex: 1,
              }}
            >
              <LinksWidget header='Useful Link' links={links2} />
            </Grid>
            <Grid
              md={6}
              xl={2}
              xs={12}
              style={{
                flex: 1,
              }}
            >
              <RecentPostsWidget header='News & Article' posts={posts} />
            </Grid>
          </Grid>
        </Container>
      </Widgets>

      <Copyright>
        <Container style={{ textAlign: "center" }}>
          <CopyrightContent>
            © {new Date().getFullYear()} <Button href='/'>Tango Atölyesi</Button>. All Rights Reserved
          </CopyrightContent>
        </Container>
      </Copyright>
    </Wrapper>
  );
}

export default Footer;
