import { Container, Grid, styled } from "@mui/material";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";
import { Title, Member } from "../../Elements";

const Section = styled(Box)({
  backgroundColor: "#eef4fc", //'#f8f8f8',
  padding: "130px 0",
});

const Button = styled("a")({
  textTransform: "uppercase",
  fontWeight: 700,
  display: "inline-block",
  border: "1px solid #efeff1",
  fontSize: "14px",
  padding: "20px 40px",
  backgroundColor: "#efeff1",
  borderRadius: "5px",
  marginTop: "30px",
  color: "#211e3b",
  textDecoration: "none",
  transition: "all 0.4s ease-in-out",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#000",
    border: "1px solid #000",
    transition: "all 0.4s ease-in-out",
  },
});

function Team(props) {
  const { title, header, members } = props;

  return (
    <Section id='our-instructors'>
      <Container>
        <Grid container spacing={2} style={{ marginTop: "40px" }}>
          <Grid item xs={12} md={6}>
            <Title title={title} header={header} />
          </Grid>
          <Grid
            xs={12}
            md={6}
            sx={{
              mt: { md: "0px", xs: "40px" },
              textAlign: "right",
            }}
          >
            <Button href='#'>
              View All Member{" "}
              <ArrowForwardIcon fontSize='small' sx={{ ml: "5px", verticalAlign: "middle" }} />
            </Button>
          </Grid>
        </Grid>

        <Grid container style={{ display: "flex", flexWrap: "wrap" }}>
          {members &&
            members.map((member, index) => (
              <Grid item xs={12} md={6} xl={3}>
                <Member
                  name={member.name}
                  position={member.position}
                  photo={member.photo}
                  facebook={member.facebook}
                  instagram={member.instagram}
                  youtube={member.youtube}
                  twitter={member.twitter}
                />
              </Grid>
            ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default Team;
