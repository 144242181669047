import React, { Fragment } from "react";
import LoadPage from "./pages";
import { Route, Routes } from "react-router-dom";
import Post from "./components/Post";

function App() {
  return (
    <Fragment>
      <Routes>
        <Route path='/' element={<LoadPage />} />
        <Route path='/:pageId' element={<LoadPage pageId />} />
        <Route path='/blog/:postId' Component={Post} />
      </Routes>
    </Fragment>
  );
}

export default App;
