import React from "react";
import { Typography, styled } from "@mui/material";

const Section = styled("div")({
  position: "relative",
  marginBottom: "30px",
  zIndex: 1,
});

const Title = styled("div")({
  marginBottom: "15px",
  color: "#af0420",
  fontWeight: "700",
  fontSize: "15px",
  lineHeight: 1,
  textTransform: "uppercase",
});

const Header = styled(Typography)({
  fontWeight: 900,
  fontSize: "42px",
  lineHeight: "50px",
  textTransform: "capitalize",
});

const FadeTitle = styled("span")({
  fontWeight: 900,
  fontSize: "120px",
  lineHeight: 1,
  color: "#211e3b",
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
  opacity: 0.05,
  zIndex: -1,
  top: "-40px",
  textTransform: "uppercase",
  margin: "0px auto",
});

function index(props) {
  const { fadeTitle, title, header, style } = props;
  return (
    <Section style={style}>
      {fadeTitle && <FadeTitle>{fadeTitle}</FadeTitle>}
      {title && <Title style={{ color: style ? "inherit" : "none" }}>{title}</Title>}
      {header && <Header>{header}</Header>}
    </Section>
  );
}

export default index;
