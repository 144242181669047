import React from 'react'
import { useParams } from "react-router-dom";
import Main from './main';
import Advanced from './advanced';
import ArgentineTango from './argentine-tango';
import Beginner from './beginner';
import Blog from './blog';
import ClassSchedule from './class-schedule';
import ClassyHeels from './classy-heels';
import ContactUs from './contact-us';
import Faq from './faq';
import Gallery from './gallery';
import Intermediate from './intermediate';
import MusicLyrics from './muisc-lyrics';
import OurAcademy from './our-academy';
import OurInstructors from './our-instructors';
import Practica from './practica';
import PreAdvanced from './pre-advanced';
import PreIntermediate from './pre-intermediate';
import PrivateLessons from './private-lessons';
import Reviews from './reviews';
import ShortSequences from './short-sequences';
import TangoPerformers from './tango-performers';
import WeddingDance from './wedding-dance';
import WeddingPackage from './wedding-package';
import WeeklyClass from './weekly-class';

function LoadPage() {
    const params = useParams();
    const pageID = ['advanced', 'argentine-tango', 'beginner', 'blog', 'class-schedule', 'classy-heels', 'contact-us', 'faq', 'gallery', 'intermediate', 'music-lyrics', 'our-academy', 'our-instructors', 'practica', 'pre-advanced', 'pre-intermediate', 'private-lessons', 'reviews', 'short-sequences', 'tango-performers', 'wedding-dance', 'wedding-package', 'weekly-class'];
    const pageURL = [<Advanced />, <ArgentineTango />, <Beginner />, <Blog />, <ClassSchedule />, <ClassyHeels />, <ContactUs />, <Faq />, <Gallery />, <Intermediate />, <MusicLyrics />, <OurAcademy />, <OurInstructors />, <Practica />, <PreAdvanced />, <PreIntermediate />, <PrivateLessons />, <Reviews />, <ShortSequences />, <TangoPerformers />, <WeddingDance />, <WeddingPackage />, <WeeklyClass />];
    return (
        <React.Fragment>
            {params.pageId ?
                pageURL[pageID.findIndex(ID => ID === params.pageId)]
                :
                <Main />}
        </React.Fragment>
    )
}

export default LoadPage