import React from 'react'
import { styled } from '@mui/material'

function index(props) {
    const { size, back, front } = props;
    const Circle = styled('div')({
        position: 'relative',
        '&:before': {
            position: "absolute",
            top: '50%',
            transform: 'translateY(-50%)',
            content: '""',
            width: `${size}px`,
            height: `${size}px`,
            right: 0,
            left: 0,
            margin: '0 auto',
            borderRadius: "50%",
            backgroundColor: `${back}`,
            zIndex:0,
        },
        '&:after': {
            position: "absolute",
            top: '50%',
            transform: 'translateY(-50%)',
            content: '""',
            width: `${size + 56}px`,
            height: `${size + 56}px`,
            right: 0,
            left: 0,
            margin: '0 auto',
            borderRadius: "50%",
            backgroundColor: `${front}`,
            zIndex:0,
        }
    })

    return (
        <Circle>
            {
                props.images.map((item, index) => (
                    <div style={{ textAlign: index % 2 ? 'left' : 'right', position: 'relative', zIndex: `${index + 1}` }}>
                        <img
                            src={item}
                            style={{
                                borderTopRightRadius: '10px',
                                borderBottomLeftRadius: '10px',
                            }}
                            alt={`item-${index}`}
                        />
                    </div>
                ))
            }
        </Circle>
    )
}

export default index