import React, { useState, useEffect, Fragment } from "react";
import Navbar from "../../components/Navbar";
import { PageBanner } from "../../components/Elements";
import ChatBox from "../../components/ChatBox";

import ScrollUp from "../../components/ScrollUp";
import Footer from "../../components/Section/Footer";
import { Link, useParams } from "react-router-dom";
import { Container, Grid, styled } from "@mui/material";
import { Visibility, QuestionAnswer, CalendarMonth, ArrowForward } from "@mui/icons-material";

import SideBar from "../Section/SideBar";

const Section = styled("section")({
  position: "relative",
  backgroundColor: "#f8f8f8",
  padding: "130px 0px",
});

const BlogPosts = styled("div")({
  margin: 0,
  padding: 0,
});

const Wrapper = styled("div")({
  position: "relative",
  marginBottom: "40px",
});

const FeaturedThumb = styled("div")({
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
  position: "relative",
  height: "460px",
  width: "100%",
  content: '""',
  backgroundColor: "#f2f2f2",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
});

const Content = styled("div")({
  padding: "50px",
  border: "2px solid #ededed",
  borderTop: 0,
  overflow: "hidden",
  "& h2": {
    fontSize: "36px",
    lineHeight: "130%",
    letterSpacing: "-3px",
  },
  "& p": {
    marginTop: "15px",
  },
});

const Meta = styled("div")({
  marginTop: "10px",
  "& span": {
    color: "#202426",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: 1,
    display: "inline-block",
    marginRight: "25px",
  },
});

const Info = styled("div")({
  marginTop: "30px",
  alignItems: "center",
  justifyContent: "space-between",
  display: "flex",
});

const Author = styled("div")({
  overflow: "hidden",
  display: "block",
  flex: 1,
});

const AuthorPhoto = styled("div")({
  height: "40px",
  width: "40px",
  lineHeight: "40px",
  borderRadius: "50%",
  background: "#f2f2f2",
  marginRight: "15px",
  float: "left",
  overflow: "hidden",
  backgroundPosition: "center",
  backgroundSize: "cover",
});

const AuthorName = styled("h5")({
  display: "inline-block",
  fontSize: "16px",
  lineHeight: 1,
  marginTop: "9px",
});

const PostLink = styled("a")({
  marginTop: 0,
  textTransform: "uppercase",
  textAlign: "right",
  fontWeight: 700,
  display: "inline-block",
  border: "1px solid #efeff1",
  fontSize: "14px",
  padding: "10px 20px",
  backgroundColor: "#efeff1",
  borderRadius: "5px",
  color: "#211e3b",
  textDecoration: "none",
  lineHeight: 1,
  transition: "all 0.4s ease-in-out",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#000",
    border: "1px solid #000",
    transition: "all 0.4s ease-in-out",
  },
});

function Post() {
  const [data, setData] = useState(null);
  const { postId } = useParams();

  useEffect(() => {
    const posts = [
      {
        id: "cozy-tango",
        thumbnail: require("../../assets/images/post-2.jpg"),
        content: {
          title: "Cozy Tango: A Sensual Beachside Affair",
          views: 100,
          comments: 0,
          date: "2nd May 2023",
          description:
            "As the summer sun begins to blaze, tango lovers around the world are gearing up for an exciting event that promises to be as hot as the sand beneath their feet. The event, called 'Cozy Tango,' will take place just steps from the beach, providing a romantic backdrop for dancers to connect and share their passion for tango...",
          article: (
            <p style={{ textAlign: "justify" }}>
              As the summer sun begins to blaze, tango lovers around the world are gearing up for an
              exciting event that promises to be as hot as the sand beneath their feet. The event,
              called <i>"Cozy Tango, "</i> will take place just steps from the beach, providing a
              romantic backdrop for dancers to connect and share their passion for tango.
              <p>
                <strong>Cozy Tango</strong> will feature a wide variety of programs to suit every
                taste and skill level. Beginners can take advantage of introductory classes, while
                intermediate and advanced dancers can challenge themselves with more complex moves
                and techniques. In addition to traditional tango dances, the event will also feature
                milongas, practicas, and other social events to help participants connect and build
                lasting relationships.
              </p>
              But what sets <strong>Cozy Tango</strong> apart from other tango events is its focus
              on creating an intimate, cozy atmosphere. Instead of overwhelming crowds and flashy
              performances, <strong>Cozy Tango</strong> is designed to be a more personal
              experience, with small groups and one-on-one interactions between dancers. Whether
              you're a seasoned tango veteran or a newcomer to the dance, you'll feel right at home
              in this warm, welcoming environment.
              <p>
                Of course, the location of the event only adds to its charm. The beachside setting
                provides a breathtaking backdrop for dancers to connect, and the warm sun and gentle
                sea breeze create an atmosphere of relaxation and sensuality. Whether you prefer to
                dance barefoot on the sand or under the stars, you'll find plenty of opportunities
                to indulge in your passion for tango.
              </p>
              <p>
                So if you're looking for a tango event that combines intimacy, sensuality, and
                beachside beauty, look no further than <strong>Cozy Tango</strong>. With its diverse
                programs and warm, welcoming atmosphere, it's sure to be a summer highlight for
                tango lovers around the world. Come join us and experience the magic of tango on the
                beach!
              </p>
            </p>
          ),
        },
        author: {
          photo: require("../../assets/images/team4.png"),
          name: "Arham Eskafi",
        },
        url: "#",
      },
      {
        id: "argentine-tango",
        thumbnail: require("../../assets/images/post-1.jpg"),
        content: {
          title: "Argentine Tango: A Passionate and Elegant Dance",
          views: 223,
          comments: 0,
          date: "24th March 2010",
          description:
            "Argentine Tango is a passionate and elegant dance that originated in Buenos Aires in the late 19th century. It is an improvised dance that requires a deep understanding of the music and culture that created it...",
          article: (
            <p style={{ textAlign: "justify" }}>
              Argentine Tango is a passionate and elegant dance that originated in Buenos Aires,
              Argentina in the late 19th century. It is a dance that requires skill, discipline, and
              a deep understanding of the music and the culture that created it.
              <p>
                One of the distinctive features of Argentine Tango is its improvisation. Unlike
                other ballroom dances that rely on pre-determined choreography, Argentine Tango is
                an improvised dance that is created on the spot by the dancers. This means that each
                dance is unique and reflects the personalities, emotions, and styles of the dancers
                involved.
              </p>
              <p>
                Another unique feature of Argentine Tango is the close embrace. The dancers embrace
                each other closely and move together in a rhythmic and sensual way. This close
                physical connection is central to the dance, as it allows the dancers to communicate
                and respond to each other's movements and emotions.
              </p>
              <p>
                Argentine Tango music is also an essential part of the dance. The music is typically
                played on a bandoneón, a type of accordion that has become synonymous with the sound
                of Tango. The music is often melancholic and nostalgic, reflecting the hardships and
                struggles of the people who created the dance.
              </p>
              <p>
                The history of Argentine Tango is closely tied to the history of Buenos Aires
                itself. In the late 19th and early 20th centuries, Buenos Aires was a melting pot of
                cultures, with immigrants from Europe, Africa, and other parts of South America
                coming together to create a unique and vibrant city. Tango was born out of this
                cultural melting pot, and it quickly became a symbol of Buenos Aires and Argentine
                culture.
              </p>
              <p>
                Today, Argentine Tango is enjoyed all over the world, with dance communities in
                Europe, Asia, and the Americas. It continues to evolve and adapt, with new styles
                and techniques emerging all the time. But at its core, Argentine Tango remains a
                dance that celebrates passion, connection, and the beauty of the human spirit.
              </p>
              In conclusion, Argentine Tango is a dance that has captured the hearts of people all
              over the world. It is a dance that celebrates the beauty and complexity of the human
              experience, and it continues to evolve and adapt to new cultural contexts. If you have
              the opportunity to experience Argentine Tango, don't hesitate to give it a try - you
              may find that it opens up a whole new world of music, movement, and connection.
            </p>
          ),
        },
        author: {
          photo: require("../../assets/images/team1.png"),
          name: "Hassan Gogani",
        },
        url: "#",
      },
    ];
    async function fetchData() {
      try {
        // const response = await fetch("../../setup/api/posts/data.json");
        // const data = await response.json();
        // setData(data);
        setData(posts.find((post) => post.id === postId));
        console.log(posts.find((post) => post.id === postId));
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [postId]);

  return (
    <Fragment>
      <ChatBox />
      <Navbar />
      <PageBanner
        text='Blog'
        header={data && data.content.title}
        breadcrumbs={[
          { label: "Home", url: "/" },
          { label: "About", url: "/our-academy" },
          { label: "News & Blog", url: "/blog" },
        ]}
        bgr={data && data.thumbnail}
      />

      <Section>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              {/* {postId && postId} */}
              {data && (
                <BlogPosts key={data.id}>
                  <Wrapper>
                    <FeaturedThumb style={{ backgroundImage: `url(${data.thumbnail})` }} />
                    <Content>
                      <h2>
                        <Link to={`/blog/${data.id}`}>{data.content.title}</Link>
                      </h2>
                      <Meta>
                        <span>
                          <Visibility
                            fontSize='inherit'
                            sx={{ margin: 0, padding: 0, paddingTop: "5px" }}
                          />
                          {data.content.views} views
                        </span>
                        <span>
                          <QuestionAnswer
                            fontSize='inherit'
                            sx={{ margin: 0, padding: 0, paddingTop: "5px" }}
                          />
                          {data.content.comments} comments
                        </span>
                        <span>
                          <CalendarMonth
                            fontSize='inherit'
                            sx={{ margin: 0, padding: 0, paddingTop: "5px" }}
                          />
                          {data.content.date}
                        </span>
                      </Meta>
                      {data.content.article}
                      {/* <Info>
                        <Author>
                          <AuthorPhoto style={{ backgroundImage: `url(${data.author.photo})` }} />
                          <AuthorName>by {data.author.name}</AuthorName>
                        </Author>
                        <PostLink
                          href={`/blog/${data.id}`} // next time I can use post.url after optimization
                          alt='Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque et'
                        >
                          Read More{" "}
                          <ArrowForward
                            fontSize='small'
                            sx={{ ml: "5px", verticalAlign: "middle" }}
                          />
                        </PostLink>
                      </Info> */}
                    </Content>
                  </Wrapper>
                </BlogPosts>
              )}
            </Grid>
            <SideBar />
          </Grid>
        </Container>
      </Section>
      <ScrollUp />
      <Footer />
    </Fragment>
  );
}

export default Post;
