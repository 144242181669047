import React, { Fragment } from "react";
import Features from "../../components/Section/Features";
import Navbar from "../../components/Navbar";
import Slider from "../../components/Slider";
import FAQ from "../../components/Section/FAQ";
import About from "../../components/Section/About";
import { Fact } from "../../components/Elements";
import Services from "../../components/Section/Services";
import ScrollUp from "../../components/ScrollUp";
import ChatBox from "../../components/ChatBox";
import Footer from "../../components/Section/Footer";
import { useTranslation } from "react-i18next";

function Main() {
  const { t } = useTranslation();

  const facts = [
    {
      id: 1,
      count: 743,
      icon: require("../../assets/images/in-studio.png"),
      caption: "trained dancers",
    },
    {
      id: 2,
      count: 30,
      icon: require("../../assets/images/milonga.png"),
      caption: "cozy tango events",
    },
    {
      id: 3,
      count: 10,
      icon: require("../../assets/images/salsa.png"),
      caption: "short sequences",
    },
    {
      id: 4,
      count: 45,
      icon: require("../../assets/images/wedding-dance.png"),
      caption: "wedding dance lessons",
    },
  ];

  const services = [
    {
      id: 1,
      src: require("../../assets/images/class.jpg"),
      title: t("milonga"),
      description: t("weekly-class"),
      overlayTitle: "Overlay Title",
      overlayDescription: "Overlay Description",
    },
    {
      id: 2,
      src: require("../../assets/images/private-lesson.jpg"),
      title: t("private-lessons"),
      description: t("Private Lessons by the top Tango masters"),
      overlayTitle: "Overlay Title",
      overlayDescription: "Overlay Description",
    },
    {
      id: 3,
      src: require("../../assets/images/cozy-event.jpg"),
      title: t("Cozy Tango"),
      description: t("Every year we have a two events in Analiya"),
      overlayTitle: "Overlay Title",
      overlayDescription: "Overlay Description",
    },
  ];

  const data = [
    {
      id: 1,
      summary: t("question1"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer1") }} />,
    },
    {
      id: 2,
      summary: t("question2"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer2") }} />,
    },
    {
      id: 3,
      summary: t("question3"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer3") }} />,
    },
    {
      id: 4,
      summary: t("question4"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer4") }} />,
    },
    {
      id: 5,
      summary: t("question5"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer5") }} />,
    },
    {
      id: 6,
      summary: t("question6"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer6") }} />,
    },
    {
      id: 7,
      summary: t("question7"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer7") }} />,
    },
    {
      id: 8,
      summary: t("question8"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer8") }} />,
    },
  ];

  return (
    <Fragment>
      <ChatBox />
      <Navbar />
      <Slider />
      <About
        short
        title={"About Tango Atölyesi"}
        header={
          <span>
            +20 Years Of Experience <br /> In Tango
          </span>
        }
        description={
          "Our Tango dance school boasts over 20 years of experience in the art form. Our passionate and knowledgeable instructors provide a supportive and welcoming environment for dancers of all levels to connect, learn, and grow through the beauty of Tango."
        }
      />
      <Fact facts={facts} />
      <Services
        services={services}
        title='Popular class & events'
        header='Learn tango with the highest publicly rated tango school in Istanbul'
        style={{
          position: "relative",
          zIndex: 1,
          color: "white",
          textAlign: "center",
        }}
      />
      <Features />
      {/* <FAQ
        title={t("faq-title")}
        header={<div dangerouslySetInnerHTML={{ __html: t("faq-subtitle") }} />}
        dataset={data}
      /> */}
      <ScrollUp />
      <Footer />
    </Fragment>
  );
}

export default Main;
