import { Container, Grid, styled } from "@mui/material";
import React from "react";
import { FormatQuote } from "@mui/icons-material";
import HeroSlider, { Slide, Nav } from "hero-slider";

const Section = styled("section")({
  position: "relative",
  backgroundColor: "#f8f8f8",
  padding: "130px 0px",
});

const Image = styled("div")({
  position: "absolute",
  left: 0,
  top: 0,
  width: "50%",
  height: "100%",
  content: '""',
  backgroundColor: "#eee",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
});

const Quote = styled(FormatQuote)({
  fontSize: "100px",
  color: "#af0420",
  lineHeight: 1,
});

const Overlay = styled("div")({
  overflow: "hidden",
});

const Testimonial = styled("div")({
  width: "100%",
});

const Review = styled("h2")({
  display: "flex",
  fontWeight: 400,
  fontStyle: "italic",
  fontSize: "30px",
  lineHeight: "140%",
  marginLeft: "60px",
});

const Info = styled("div")({
  marginTop: "30px",
  display: "flex",
  marginLeft: "60px",
  alignItems: "center",
});

const ProfilePhoto = styled("div")({
  borderRadius: "50%",
  backgroundColor: "#eee",
  width: "70px",
  height: "70px",
  lineHeight: "70px",
  float: "left",
  overflow: "hidden",
  marginRight: "25px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  position: "relative",
  backgroundPosition: "center",
});

const Bio = styled("div")({
  overflow: "auto",
});

const Name = styled("h3")({
  fontWeight: 500,
  fontSize: "18px",
  fontStyle: "italic",
  textTransform: "capitalize",
});

const Position = styled("p")({
  textTransform: "uppercase",
  fontSize: "12px",
  fontStyle: "italic",
  fontWeight: 900,
  color: "#af0420",
});

function index(props) {
  const { bgr, testimonials } = props;

  return (
    <Section>
      <Image style={{ backgroundImage: `url(${bgr})` }} />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            lg={6}
            xl={12}
            sx={{ ml: "58.33%" }}
            // sx={{
            //   [theme.breakpoints.up("lg")]: {
            //     marginLeft: "50%",
            //   },
            //   [theme.breakpoints.up("xl")]: {
            //     marginLeft: "58.33%",
            //   },
            // }}
          >
            <HeroSlider
              height='50vh'
              width='120%'
              autoplay
              accessability={{
                shouldDisplayButtons: false,
              }}
              controller={{
                initialSlide: 1,
                slidingDuration: 10,
                slidingDelay: 100,
              }}
            >
              {testimonials &&
                testimonials.map((testimonial, index) => (
                  <Slide key={`${testimonial.id}-${index}`}>
                    <Overlay>
                      <Testimonial>
                        <Quote />
                        <Review>{testimonial.review}</Review>
                        <Info>
                          <ProfilePhoto
                            style={{
                              backgroundImage: `url(${testimonial.photo})`,
                            }}
                          ></ProfilePhoto>
                          <Bio>
                            <Name>{testimonial.name}</Name>
                            <Position>{testimonial.position}</Position>
                          </Bio>
                        </Info>
                      </Testimonial>
                    </Overlay>
                  </Slide>
                ))}
              <Nav />
            </HeroSlider>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default index;
