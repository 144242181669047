import React from "react";
import { styled } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const Navigation = styled("nav")({
  margin: 0,
  padding: 0,
  color: "#fff",
});

const Breadcrumb = styled("ol")({
  marginTop: "30px",
  background: "transparent",
  padding: 0,
  display: "flex",
  flex: "wrap",
  marginBottom: "1rem",
  listStyle: "none",
  borderRadius: ".25rem",
  alignItems: "center",
  zIndex: 1,
});

const Item = styled("li")({
  display: "flex",
  alignItems: "center",
});

const Link = styled("a")({
  color: "#fff",
  fontWeight: 600,
  fontSize: "20px",
  textTransform: "capitalize",
});

function index({ breadcrumbs }) {
  return (
    <Navigation>
      <Breadcrumb>
        {breadcrumbs.map(({ label, url }, index) => (
          <>
            <Item>
              <Link href={url}>{label}</Link>
            </Item>
            {index < breadcrumbs.length - 1 && (
              <NavigateNextIcon
                style={{
                  //   verticalAlign: "middle",
                  color: "#fff",
                }}
              />
            )}
          </>
        ))}
      </Breadcrumb>
    </Navigation>
  );
}

export default index;
