import React from "react";
import { styled } from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";

const WidgetBox = styled("div")({
  border: "2px solid #ededed",
  boxSizing: "border-box",
  padding: "40px",
  marginBottom: "40px",
});

const WidgetTitle = styled("div")({
  marginBottom: "35px",
  position: "relative",
  paddingLeft: "45px",
  "&:before": {
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
    content: '""',
    height: "4px",
    width: "18px",
    borderRadius: "5px",
    position: "absolute",
    backgroundColor: "#af0420",
  },
  "&:after": {
    left: "22px",
    top: "50%",
    transform: "translateY(-50%)",
    content: '""',
    height: "4px",
    width: "4px",
    borderRadius: "5px",
    position: "absolute",
    backgroundColor: "#af0420",
  },
});

const Title = styled("h3")({});

const Posts = styled("div")({});

const Post = styled("div")({
  overflow: "hidden",
  paddingBottom: "30px",
  marginBottom: "30px",
  borderBottom: "1px solid #f2f2f2",
  cursor: "pointer",
});

const Thumbnail = styled("div")({
  width: "80px",
  height: "80px",
  backgroundColor: "#f2f2f2",
  borderRadius: "50%",
  float: "left",
  marginRight: "20px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
});

const Content = styled("div")({});
const Header = styled("h5")({
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: 700,
  color: "#211e3b",
});
const Date = styled("div")({
  display: "flex",
  marginTop: "5px",
  fontWeight: 500,
  fontSize: "14px",
  verticalAlign: "middle",
  marginRight: "5px",
  transition: "all 0.3s ease-in-out",
  color: "inherit",
  alignContent: "center",
});

function index(props) {
  const { title, posts } = props;
  return (
    <WidgetBox>
      <WidgetTitle>
        <Title>{title}</Title>
      </WidgetTitle>
      <Posts>
        {posts &&
          posts.map((post, index) => (
            <Post key={`post-${post.id}${index}`}>
              <Thumbnail
                style={{
                  backgroundImage: `url(${post.thumbnail})`,
                }}
              />
              <Content>
                <Header>
                  <a href={post.url}>{post.content.title}</a>
                </Header>
                <Date>
                  <CalendarMonth fontSize='small' />
                  {post.content.date}
                </Date>
              </Content>
            </Post>
          ))}
      </Posts>
    </WidgetBox>
  );
}

export default index;
