import React, { Fragment } from "react";
import { Container, Grid, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

import Navbar from "../../components/Navbar";

import ScrollUp from "../../components/ScrollUp";
import ChatBox from "../../components/ChatBox";
import Footer from "../../components/Section/Footer";

import bgr from "../../assets/images/wedding-dance-lessons-banner.png";
import { Fact, PageBanner } from "../../components/Elements";
import Services from "../../components/Section/Services";
import PriceList from "../../components/Section/Price List";
// import About from '../../components/Section/About'
// import Calendar from '../../components/Section/Calendar'
import NightlifeIcon from "@mui/icons-material/Nightlife";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import MoodIcon from "@mui/icons-material/Mood";
import About from "../../components/Section/About";

const Section = styled("section")({
  position: "relative",
  backgroundColor: "#eef4fc",
  padding: "130px 0px",
  paddingTop: "35px",
});

function WeedingDance() {
  const { t, i18n } = useTranslation();
  const facts = [
    {
      id: 1,
      count: 743,
      icon: require("../../assets/images/in-studio.png"),
      caption: "trained dancers",
    },
    {
      id: 2,
      count: 30,
      icon: require("../../assets/images/milonga.png"),
      caption: "cozy tango events",
    },
    {
      id: 3,
      count: 10,
      icon: require("../../assets/images/salsa.png"),
      caption: "short sequences",
    },
    {
      id: 4,
      count: 45,
      icon: require("../../assets/images/wedding-dance.png"),
      caption: "wedding dance lessons",
    },
  ];

  const services = [
    {
      id: 1,
      src: require("../../assets/images/class.jpg"),
      title: t("milonga"),
      description: t("weekly-class"),
      overlayTitle: "Overlay Title",
      overlayDescription: "Overlay Description",
    },
    {
      id: 2,
      src: require("../../assets/images/private-lesson.jpg"),
      title: t("private-lessons"),
      description: t("Private Lessons by the top Tango masters"),
      overlayTitle: "Overlay Title",
      overlayDescription: "Overlay Description",
    },
    {
      id: 3,
      src: require("../../assets/images/cozy-event.jpg"),
      title: t("Cozy Tango"),
      description: t("Every year we have a two events in Analiya"),
      overlayTitle: "Overlay Title",
      overlayDescription: "Overlay Description",
    },
  ];

  return (
    <Fragment>
      <ChatBox />
      <Navbar />
      <PageBanner
        text='Wedding'
        header='Wedding Dance Lessons'
        breadcrumbs={[
          { label: "Home", url: "/" },
          { label: "Wedding", url: "#" },
          { label: "Wedding Dance Lessons", url: "#" },
        ]}
        bgr={bgr}
      />
      <About
        title={"A beautiful tango for your wedding"}
        header={"Argentine tango"}
        description={
          <>
            <p>
              We know you are busy with other wedding arrangements so we’ll make preparing your
              first dance enjoyable and straight forward. So go on, just enjoy the journey{" "}
              <strong>– it’s a beautiful one.</strong>
            </p>
            <p>
              <strong>
                We’ll help you pick a tune that makes you both swoon, steps to make your mum proud
              </strong>
              , and create a wedding choreography that feels like you: either filled with
              adrenaline-fueled kicks and flicks, or delicate and sweet. We can also offer advice
              about wedding dresses.
            </p>
          </>
        }
        image={require("../../assets/images/wedding-dance-lessons.png")}
        goals={[
          {
            id: 1,
            title: "How to get started?",
            description: (
              <>
                <p>
                  To get started, simply call us (+90 551 941 23 61), choose a tango wedding package
                  at the bottom of the page, or send us a mail to let us know what you have in mind.{" "}
                </p>
              </>
            ),
          },
          {
            id: 1,
            title: "Do you have a custom package?",
            description: (
              <>
                <p>
                  We have packages to make it simpler for you to get started, but we’ll always be{" "}
                  <strong>happy to customise one for you</strong> – simply contact us if you have
                  specific requirements.{" "}
                </p>
              </>
            ),
          },
        ]}
        // showFeatures={true}
      />

      {/* <Fact facts={facts} /> */}
      <Services
        services={services}
        title='Popular class & events'
        header='Learn tango with the highest publicly rated tango school in Istanbul'
        style={{
          position: "relative",
          zIndex: 1,
          color: "white",
          textAlign: "center",
        }}
      />
      <PriceList mode='wedding' />
      <ScrollUp />
      <Footer />
    </Fragment>
  );
}

export default WeedingDance;
