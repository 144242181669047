import React from "react";
import { List, ListItem, ListItemText, styled } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Section = styled("div")({
  display: "flex",
  alignItems: "center",
  marginTop: "20px",
});

const Image = styled("div")({
  minWidth: "200px",
  height: "160px",
  backgroundSize: "cover",
});

const CheckList = styled(List)({
  marginLeft: "20px",
});

const Item = styled(ListItem)({});

const ItemText = styled(ListItemText)({
  fontWeight: 900,
  fontStyle: "italic",
});

function index(props) {
  const { items, src } = props;
  return (
    <Section>
      {src ? <Image style={{ backgroundImage: `url(${src})` }} /> : null}
      <CheckList>
        {items.map((item, index) => (
          <Item disablePadding>
            <CheckCircleIcon color='success' sx={{ mr: "10px", verticalAlign: "middle" }} />
            <ItemText primary={item} />
          </Item>
        ))}
      </CheckList>
    </Section>
  );
}

export default index;
