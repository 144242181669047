import React from "react";
import { styled } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const ImageBox = styled("div")({
  position: "relative",
  verticalAlign: "middle",
  width: "100%",
  height: "100%",
});

const Image = styled("div")({
  position: "relative",
  backgroundColor: "#fff",
  width: "auto",
  minHeight: "550px",
  height: "100%",
  borderRadius: "10px",
  // borderTopRightRadius: "10px",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  zIndex: 1,
});

const Ribbon = styled("span")({
  marginRight: "-15px",
  borderBottom: "200px solid #af0420",
  borderLeft: "200px solid transparent",
  borderRadius: "0px 0px 0px 0px",
  position: "absolute",
  bottom: "-15px",
  content: '""',
  width: 0,
  height: 0,
});

function index(props) {
  const { src, dir } = props;
  return (
    <ImageBox>
      <span class='dot-circle'></span>
      <Image style={{ backgroundImage: `url(${src})` }} />
      <Ribbon style={{ right: `${dir === "r" ? 0 : "none"}` }} />
    </ImageBox>
  );
}

export default index;
