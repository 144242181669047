import React, { useState } from "react";
import { styled } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ServiceBox = styled("div")({
  borderRadius: "7px",
  backgroundColor: "white",
  textAlign: "center",
  position: "relative",
  zIndex: 1,
  transition: "all 0.4s ease-in-out",
  padding: "20px",
  overflow: "hidden",
  marginTop: "30px",
  display: "block",
  boxSizing: "border-box",
  cursor: "pointer",
});

const Image = styled("div")({
  borderRadius: "7px",
  height: "230px",
  width: "100%",
  overflow: "hidden",
  transition: "all 0.4s ease-in-out",
  backgroundColor: "#eee",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  position: "relative",
  backgroundPosition: "center",
});

const VisibleContent = styled("div")({
  transition: "all 0.4s ease-in-out",
  marginTop: "20px",
});

const OverlayContent = styled("div")({
  padding: "20px 30px",
  opacity: 0,
  visibility: "hidden",
  backgroundColor: "#fff",
  position: "absolute",
  content: '""',
  transition: "all 0.4s ease-in-out",
  transform: "translateY(-0%)",
  textAlign: "center",
  bottom: 0,
  left: 0,
  right: 0,
});

const Title = styled("h4")({
  fontSize: "22px",
  lineHeight: "28px",
  fontWeight: 700,
});

const Description = styled("p")({
  marginTop: "5px",
});

const Button = styled("a")({
  textTransform: "uppercase",
  fontSize: "14px",
  fontWeight: 700,
  marginTop: "5px",
  display: "block",
});

function Index(props) {
  const { src, title, description, overlayTitle, overlayDescription } = props;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <ServiceBox onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Image style={{ backgroundImage: `url(${src})` }} />
      <VisibleContent>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </VisibleContent>
      <OverlayContent
        style={{
          opacity: isHovered ? 1 : 0,
          visibility: isHovered ? "visible" : "hidden",
        }}
      >
        <Title style={{ marginBottom: "5px" }}>{overlayTitle}</Title>
        <Description>{overlayDescription}</Description>
        <Button href='#'>
          Learn more <ArrowForwardIcon fontSize='small' sx={{ ml: "5px", verticalAlign: "middle" }} />
        </Button>
      </OverlayContent>
    </ServiceBox>
  );
}

export default Index;
