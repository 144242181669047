import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";

import ScrollUp from "../../components/ScrollUp";
import ChatBox from "../../components/ChatBox";
import Footer from "../../components/Section/Footer";
import bgr from "../../assets/images/contact-us-banner.png";
import { Container, Grid, Box, styled } from "@mui/material";

import MailIcon from "@mui/icons-material/Mail";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import PhoneIcon from "@mui/icons-material/Phone";
import { PageBanner } from "../../components/Elements";

const Section = styled(Box)({
  backgroundColor: "#eef4fc", //'#f8f8f8',
  padding: "130px 0",
  // paddingBottom: '80px',
});

const SingleCotnactCard = styled("div")({
  backgroundColor: "#f8f8f8",
  padding: "40px",
  marginBottom: "30px",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    backgroundColor: "#202426",
  },
});

const TopPart = styled("div")({
  overflow: "hidden",
  ".icon": {
    width: "70px",
    height: "70px",
    fontSize: "20px",
    lineHeight: "70px",
    borderRadius: "50%",
    backgroundColor: "#fff",
    textAlign: "center",
    float: "left",
    color: "#086ad7",
    overflow: "hidden",
    marginRight: "20px",
  },
  ".title": {
    h4: {
      fontWeight: 900,
    },
    span: {
      color: "#c5c7c8",
      fontSize: "17px",
      fontWeight: 900,
      lineHeight: "32px",
    },
  },
});

const BottomPart = styled("div")({
  marginTop: "25px",
  overflow: "hidden",
  ".info": {
    display: "inline-block",
    p: {
      color: "#c5c7c8",
      fontSize: "17px",
      fontWeight: 900,
      lineHeight: "32px",
    },
  },
  ".icon": {
    width: "50px",
    height: "50px",
    fontSize: "14px",
    lineHeight: "50px",
    borderRadius: "50%",
    backgroundColor: "#fff",
    textAlign: "center",
    float: "right",
    overflow: "hidden",
    marginRight: "20px",
  },
});

const SectionTitle = styled("div")({
  position: "relative",
  zIndex: 1,
  marginBottom: "30px",
  "& span": {
    fontWeight: 900,
    fontSize: "120px",
    lineHeight: 1,
    color: "#211e3b",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    opacity: 0.05,
    zIndex: -1,
    top: "-40px",
    textTransform: "uppercase",
    margin: "0px auto",
  },
  "& p": {
    color: "rgb(175, 4, 32)",
    marginBottom: "15px",
    fontWeight: 700,
    fontSize: "15px",
    lineHeight: 1,
    textTransform: "uppercase",
  },
  "& h1": {
    lineHeight: "50px",
    fontWeight: 900,
    textTransform: "capitalize",
  },
});

const SinglePersonalInfo = styled("div")({
  marginBottom: "20px",
  label: {
    color: "#202426",
    fontWeight: 700,
    fontSize: 14,
    marginBottom: "10px",
    textTransform: "capitalize",
  },
  input: {
    width: "100%",
    lineHeight: 1,
    backgroundColor: "#f8f8f8",
    padding: "25px 30px",
    border: 0,
    borderRadius: "7px",
  },
  textarea: {
    height: "220px",
    width: "100%",
    lineHeight: 1,
    backgroundColor: "#f8f8f8",
    padding: "25px 30px",
    border: 0,
    borderRadius: "7px",
  },
});

function ContactUs() {
  return (
    <Fragment>
      <ChatBox />
      <Navbar />
      <PageBanner header='Contact Us' breadcrumb='Contact Us' bgr={bgr} />
      <Section>
        <Container>
          <Grid container spacing={2}>
            <Grid item lg={4} md={6} xs={12}>
              <SingleCotnactCard>
                <TopPart>
                  <div class='icon'>
                    <MailIcon style={{ verticalAlign: "middle" }} />
                  </div>
                  <div class='title'>
                    <h4>Email Address</h4>
                    <span>Sent mail asap anytime</span>
                  </div>
                </TopPart>
                <BottomPart>
                  <div class='info'>
                    <p>info@tangoatolyesi.com</p>
                    <p>support@tangoatolyesi.com</p>
                  </div>
                  <div class='icon'>
                    <ArrowForwardIcon style={{ verticalAlign: "middle" }} />
                  </div>
                </BottomPart>
              </SingleCotnactCard>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <SingleCotnactCard>
                <TopPart>
                  <div class='icon'>
                    <PhoneIcon style={{ verticalAlign: "middle" }} />
                  </div>
                  <div class='title'>
                    <h4>Phone Number</h4>
                    <span>call us asap anytime</span>
                  </div>
                </TopPart>
                <BottomPart>
                  <div class='info'>
                    <p>+90 551 941 23 61</p>
                    <p>+(90) 551-941-2361</p>
                  </div>
                  <div class='icon'>
                    <ArrowForwardIcon style={{ verticalAlign: "middle" }} />
                  </div>
                </BottomPart>
              </SingleCotnactCard>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <SingleCotnactCard>
                <TopPart>
                  <div class='icon'>
                    <FmdGoodIcon style={{ verticalAlign: "middle" }} />
                  </div>
                  <div class='title'>
                    <h4>Office Address</h4>
                    <span>Sent mail asap anytime</span>
                  </div>
                </TopPart>
                <BottomPart>
                  <div class='info'>
                    <p>Kadıköy Çarşısı Zemin kat 10</p>
                    <p>34734 Kadıköy/İstanbul</p>
                  </div>
                  <div class='icon'>
                    <ArrowForwardIcon style={{ verticalAlign: "middle" }} />
                  </div>
                </BottomPart>
              </SingleCotnactCard>
            </Grid>
          </Grid>

          <Grid Container spacing={2}>
            <Grid item lg={12} xs={12}>
              <div class='contact-map-wrap'>
                <div id='map'>
                  <iframe
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d713.9356001001491!2d29.022549500883343!3d40.990143021755706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab91484c863b1%3A0x759473891d448158!2sTango%20At%C3%B6lyesi!5e0!3m2!1sen!2str!4v1682510611820!5m2!1sen!2str'
                    frameborder='0'
                    style={{
                      border: 0,
                      width: "100%",
                      height: "450px",
                    }}
                    allowfullscreen=''
                    aria-hidden='false'
                    tabindex='0'
                  ></iframe>
                </div>
              </div>
            </Grid>
          </Grid>

          <Section style={{ paddingBottom: 0 }}>
            {/* , display: 'flex', flexWrap: 'wrap', marginRight: '-15px', marginLeft:'-15px' */}
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ textAlign: "center", marginBottom: "20px" }}>
                <SectionTitle>
                  <p>send us message</p>
                  <h1>
                    Don’t Hesited To Contact Us <br /> Say Hello or Message
                  </h1>
                </SectionTitle>
              </Grid>

              <Grid item lg={12} xs={12}>
                <div class='contact-form' style={{ paddingTop: "40px" }}>
                  <form action='#' class='row conact-form'>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <SinglePersonalInfo>
                          <label for='fname'>Full Name</label>
                          <input type='text' id='fname' placeholder='Enter Name' />
                        </SinglePersonalInfo>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <SinglePersonalInfo>
                          <label for='email'>Email Address</label>
                          <input type='email' id='email' placeholder='Enter Email Address' />
                        </SinglePersonalInfo>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <SinglePersonalInfo>
                          <label for='phone'>Phone Number</label>
                          <input type='text' id='phone' placeholder='Enter Number' />
                        </SinglePersonalInfo>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <SinglePersonalInfo>
                          <label for='subject'>Subject</label>
                          <input type='text' id='subject' placeholder='Enter Subject' />
                        </SinglePersonalInfo>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <SinglePersonalInfo>
                          <label for='subject'>Enter Message</label>
                          <textarea placeholder='Enter message'></textarea>
                        </SinglePersonalInfo>
                      </Grid>
                      <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
                        <button
                          to='#'
                          style={{
                            color: "#fff",
                            padding: "20px 40px",
                            borderRadius: "5px",
                            fontSize: "14px",
                            backgroundColor: "rgb(175, 4, 32)",
                            border: "1px solid rgb(175, 4, 32)",
                            boxSizing: "border-box",
                            lineHeight: 1,
                            display: "inline-block",
                            textTransform: "uppercase",
                            transition: "all 0.4s ease-in-out",
                            marginTop: "30px",
                            fontWeight: 900,
                          }}
                        >
                          send message <ArrowForwardIcon style={{ verticalAlign: "middle" }} color='white' />
                        </button>
                        {/* <button type="submit" class="theme-btn">send  message <i class="fas fa-arrow-right"></i></button> */}
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </Grid>
            </Grid>
          </Section>
        </Container>
      </Section>
      <ScrollUp />
      <Footer />
    </Fragment>
  );
}

export default ContactUs;
