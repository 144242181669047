import HeroSlider, { Slide, Overlay, Nav, AutoplayButton } from 'hero-slider';

import slider_1 from '../../assets/images/slider-1.jpg';
import slider_2 from '../../assets/images/slider-2.jpg';
import slider_3 from '../../assets/images/slider-3.jpg';

const slides = [
    {
        title: 'Passion in Motion',
        description: 'Dynamic shots of intense tango movement',
        backgroundImage: `url(${slider_1})`,
    },
    {
        title: 'The Beauty of Connection',
        description: 'Intimate moments showcasing tango\'s communication',
        backgroundImage: `url(${slider_2})`,
    },
    {
        title: 'A Window into Buenos Aires',
        description: 'Images of tango\'s cultural heritage in Argentina',
        backgroundImage: `url(${slider_3})`,
    },
];

const Slider = () => {
    return (
        <HeroSlider            
            autoplay={{
                autoplayDuration: 5000,
                autoplayDebounce: 0
            }}
            // slidingAnimation="left_to_right"
            style={{
                marginTop: '45px'
            }}
            orientation="horizontal"
            initialSlide={1}
            settings={{
                slidingDuration: 500,
                slidingDelay: 100,
            }}
            height='69vh'
        >
            <Overlay>
                <AutoplayButton style={{ color: 'white' }} />
            </Overlay>
            {slides.map((slide, index) => (
                <Slide
                    key={index}
                    background={{
                        backgroundImage: slide.backgroundImage,
                        backgroundPosition: 'top center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundAnimation: 'fade',
                        backgroundColor: '#8A8A8A',
                        maskBackgroundBlendMode: 'luminosity',
                    }}
                >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexFlow: 'column',
                        width: '100%',
                        height: '100%',
                        margin: 0,
                        padding: 0,
                        pointerEvents: 'none',
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        color: 'white',
                    }}>
                        {/* <Overlay> */}
                        <h1 style={{ fontSize: '74px', fontWeight: 900 }}>{slide.title}</h1>
                        <p style={{ fontSize: '40px', fontWeight: 900 }}>{slide.description}</p>
                        {/* </Overlay> */}
                    </div>
                </Slide>
            ))}
            <Nav arrowStyle={{
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                borderRadius: '50%',
                padding: '10px',
            }}
            />
        </HeroSlider>
    );
};

export default Slider;
