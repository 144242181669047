import React from "react";
import { Grid, styled } from "@mui/material";

import { AboutMe, Categories, Feed, InstagramFeed, Search, Tag } from "../../Elements";

const MainSideBar = styled("div")({
  marginLeft: "20px",
});

function SideBar() {
  return (
    <Grid item xs={12} lg={4}>
      <MainSideBar>
        <AboutMe
          title={"Tango Atölyesi"}
          image={require("../../../assets/brand/logo-square.png")}
          caption={"About Me"}
          description={
            <span>
              <strong>Tango Dance Academy</strong> in the Heart of the City of Joy and Dance <br />
              <strong>Istanbul, Kadiköy District</strong>
            </span>
          }
          instagram={"#"}
          facebook={"#"}
          twitter={"#"}
          youtube={"#"}
        />
        <Search title={"Search Objects"} placeholder={"Search your keyword..."} />
        <Feed
          title={"Popular Feeds"}
          posts={[
            {
              id: 1,
              thumbnail: require("../../../assets/images/post-2.jpg"),
              content: {
                title: "Cozy Tango: A Sensual Beachside Affair",
                views: 100,
                comments: 0,
                date: "2nd May 2023",
                description:
                  "As the summer sun begins to blaze, tango lovers around the world are gearing up for an exciting event that promises to be as hot as the sand beneath their feet. The event, called 'Cozy Tango,' will take place just steps from the beach, providing a romantic backdrop for dancers to connect and share their passion for tango...",
              },
              author: {
                photo: require("../../../assets/images/team4.png"),
                name: "Arham Eskafi",
              },
              url: "#",
            },
          ]}
        />

        <Categories
          title={"Categories"}
          categories={[
            {
              id: 1,
              name: "Tango",
              count: 31,
              url: "/blog/category/tango",
            },
            {
              id: 2,
              name: "Dance",
              count: 17,
              url: "/blog/category/dance",
            },
            {
              id: 3,
              name: "Events",
              count: 8,
              url: "/blog/category/events",
            },
            {
              id: 4,
              name: "Wedding",
              count: 2,
              url: "/blog/category/wedding",
            },
          ]}
        />

        <InstagramFeed title={"Instagram Feeds"} />

        <Tag
          title={"Popular Tags"}
          tags={[
            {
              id: 1,
              name: "dance",
              url: "/blog/tag/dance",
              value: 21,
            },
            {
              id: 2,
              name: "events",
              url: "/blog/tag/events",
              value: 7,
            },
            {
              id: 3,
              name: "tango-night",
              url: "/blog/tag/tango-night",
              value: 2,
            },
          ]}
        />

        {/* <SingleSideBarWidget>
                    <div class="ad-wraper">
                        <a href="#" target="_blank">
                            <img src="assets/img/blog/blog-ad.jpg" alt="" />
                        </a>
                    </div>
                </SingleSideBarWidget> */}
      </MainSideBar>
    </Grid>
  );
}

export default SideBar;
