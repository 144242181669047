import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Grid, styled } from "@mui/material";
import { Visibility, QuestionAnswer, CalendarMonth, ArrowForward } from "@mui/icons-material";

import SideBar from "../Section/SideBar";

const Section = styled("section")({
  position: "relative",
  backgroundColor: "#f8f8f8",
  padding: "130px 0px",
});

const BlogPosts = styled("div")({
  margin: 0,
  padding: 0,
});

const Post = styled("div")({
  position: "relative",
  marginBottom: "40px",
});
const FeaturedThumb = styled("div")({
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
  position: "relative",
  height: "460px",
  width: "100%",
  content: '""',
  backgroundColor: "#f2f2f2",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
});

const Content = styled("div")({
  padding: "50px",
  border: "2px solid #ededed",
  borderTop: 0,
  overflow: "hidden",
  "& h2": {
    fontSize: "36px",
    lineHeight: "130%",
    letterSpacing: "-3px",
  },
  "& p": {
    marginTop: "15px",
  },
});

const Meta = styled("div")({
  marginTop: "10px",
  "& span": {
    color: "#202426",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: 1,
    display: "inline-block",
    marginRight: "25px",
  },
});

const Info = styled("div")({
  marginTop: "30px",
  alignItems: "center",
  justifyContent: "space-between",
  display: "flex",
});

const Author = styled("div")({
  overflow: "hidden",
  display: "block",
  flex: 1,
});

const AuthorPhoto = styled("div")({
  height: "40px",
  width: "40px",
  lineHeight: "40px",
  borderRadius: "50%",
  background: "#f2f2f2",
  marginRight: "15px",
  float: "left",
  overflow: "hidden",
  backgroundPosition: "center",
  backgroundSize: "cover",
});

const AuthorName = styled("h5")({
  display: "inline-block",
  fontSize: "16px",
  lineHeight: 1,
  marginTop: "9px",
});

const PostLink = styled(Link)({
  marginTop: 0,
  textTransform: "uppercase",
  textAlign: "right",
  fontWeight: 700,
  display: "inline-block",
  border: "1px solid #efeff1",
  fontSize: "14px",
  padding: "10px 20px",
  backgroundColor: "#efeff1",
  borderRadius: "5px",
  color: "#211e3b",
  textDecoration: "none",
  lineHeight: 1,
  transition: "all 0.4s ease-in-out",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#000",
    border: "1px solid #000",
    transition: "all 0.4s ease-in-out",
  },
});

function Blog() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        // const response = await fetch("../../setup/api/posts/data.json");
        // const data = await response.json();
        // setData(data);
        setData([
          {
            id: "cozy-tango",
            thumbnail: require("../../assets/images/post-2.jpg"),
            content: {
              title: "Cozy Tango: A Sensual Beachside Affair",
              views: 100,
              comments: 0,
              date: "2nd May 2023",
              description:
                "As the summer sun begins to blaze, tango lovers around the world are gearing up for an exciting event that promises to be as hot as the sand beneath their feet. The event, called 'Cozy Tango,' will take place just steps from the beach, providing a romantic backdrop for dancers to connect and share their passion for tango...",
            },
            author: {
              photo: require("../../assets/images/team4.png"),
              name: "Arham Eskafi",
            },
            url: "#",
          },
          {
            id: "argentine-tango",
            thumbnail: require("../../assets/images/post-1.jpg"),
            content: {
              title: "Argentine Tango: A Passionate and Elegant Dance",
              views: 223,
              comments: 0,
              date: "24th March 2010",
              description:
                "Argentine Tango is a passionate and elegant dance that originated in Buenos Aires in the late 19th century. It is an improvised dance that requires a deep understanding of the music and culture that created it...",
            },
            author: {
              photo: require("../../assets/images/team1.png"),
              name: "Hassan Gogani",
            },
            url: "#",
          },
        ]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return (
    <Section>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            {data.map((post, index) => (
              <BlogPosts key={index}>
                <Post>
                  <FeaturedThumb style={{ backgroundImage: `url(${post.thumbnail})` }} />
                  <Content>
                    <h2>
                      <Link to={`/blog/${post.id}`}>{post.content.title}</Link>
                    </h2>
                    <Meta>
                      <span>
                        <Visibility
                          fontSize='inherit'
                          sx={{ margin: 0, padding: 0, paddingTop: "5px" }}
                        />
                        {post.content.views} views
                      </span>
                      <span>
                        <QuestionAnswer
                          fontSize='inherit'
                          sx={{ margin: 0, padding: 0, paddingTop: "5px" }}
                        />
                        {post.content.comments} comments
                      </span>
                      <span>
                        <CalendarMonth
                          fontSize='inherit'
                          sx={{ margin: 0, padding: 0, paddingTop: "5px" }}
                        />
                        {post.content.date}
                      </span>
                    </Meta>
                    <p>{post.content.description}</p>
                    <Info>
                      <Author>
                        <AuthorPhoto style={{ backgroundImage: `url(${post.author.photo})` }} />
                        <AuthorName>by {post.author.name}</AuthorName>
                      </Author>
                      <PostLink
                        to={`/blog/${post.id}`} // next time I can use post.url after optimization
                        alt='Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque et'
                      >
                        Read More{" "}
                        <ArrowForward
                          fontSize='small'
                          sx={{ ml: "5px", verticalAlign: "middle" }}
                        />
                      </PostLink>
                    </Info>
                  </Content>
                </Post>
              </BlogPosts>
            ))}
          </Grid>
          <SideBar />
        </Grid>
      </Container>
    </Section>
  );
}

export default Blog;
