import React from "react";
import { styled } from "@mui/material";

const WidgetBox = styled("div")({
  border: "2px solid #ededed",
  boxSizing: "border-box",
  padding: "40px",
  marginBottom: "40px",
});

const WidgetTitle = styled("div")({
  marginBottom: "35px",
  position: "relative",
  paddingLeft: "45px",
  "&:before": {
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
    content: '""',
    height: "4px",
    width: "18px",
    borderRadius: "5px",
    position: "absolute",
    backgroundColor: "#af0420",
  },
  "&:after": {
    left: "22px",
    top: "50%",
    transform: "translateY(-50%)",
    content: '""',
    height: "4px",
    width: "4px",
    borderRadius: "5px",
    position: "absolute",
    backgroundColor: "#af0420",
  },
});

const Title = styled("h3")({});

const Feeds = styled("div")({
  width: "100%",
  overflow: "hidden",
  marginLeft: "-5px",
});

const Photo = styled("a")({
  float: "left",
  marginLeft: "4%",
  backgroundColor: "#f8f8f8",
  height: "80px",
  width: "29%",
  overflow: "hidden",
  marginBottom: "10px",
  position: "relative",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  transition: "all 0.4s ease-in-out",
});

function index(props) {
  const { title, feeds } = props;
  return (
    <WidgetBox>
      <WidgetTitle>
        <Title>{title}</Title>
      </WidgetTitle>
      <Feeds>
        {feeds &&
          feeds.map((feed, index) => (
            <Photo
              key={`photo-${feed.id}${index}`}
              href={feed.photo}
              style={{
                backgroundImage: `url(${feed.photo})`,
              }}
            />
          ))}
      </Feeds>
    </WidgetBox>
  );
}

export default index;
