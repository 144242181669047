import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";

import ScrollUp from "../../components/ScrollUp";
import ChatBox from "../../components/ChatBox";
import Footer from "../../components/Section/Footer";
import bgr from "../../assets/images/music-banner.jpg";

import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { Container, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Spotify } from "react-spotify-embed";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Description, PageBanner, Title } from "../../components/Elements";

const Section = styled("section")({
  backgroundColor: "#eef4fc",
  position: "relative",
  zIndex: 1,
  overflow: "hidden",
  padding: "70px 0px",
});

const SVG = styled("svg")({
  position: "absolute",
  right: 0,
  bottom: "0px",
  zIndex: -1,
  width: "100%",
  height: "220px",
});

const Button = styled("a")({
  textTransform: "uppercase",
  fontSize: "14px",
  fontWeight: 700,
  display: "block",
  padding: "20px 40px",
  backgroundColor: "#af0420",
  borderRadius: "5px",
  marginTop: "30px",
  color: "#fff",
  textDecoration: "none",
  transition: "all 0.4s ease-in-out",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#000",
  },
});

function MusicLyrics() {
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  return (
    <Fragment>
      <ChatBox />
      <Navbar />
      <PageBanner text='Music' header='Music & Lyrics' breadcrumb='Music & Lyrics' bgr={bgr} />
      {/* Look like a Features section component  */}
      <Section>
        <Container>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} xl={6}>
              <Grid container spacing={2} mt={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Spotify
                    style={{
                      width: "100%",
                      minHeight: "550px",
                    }}
                    link='https://open.spotify.com/playlist/0bIWNYDBIA2cAbo8tioDa1?si=b36d8f8f0ff2485b'

                    // link="https://open.spotify.com/playlist/3rW4XaUnxKYZbTWg4WtuSG?si=25b51fe3887e46fa"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              xl={5}
              mt={5}
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                [theme.breakpoints.up("xl")]: {
                  marginLeft: "8.3%",
                },
              }}
            >
              <Title
                title={
                  <p>
                    The Music{" "}
                    <strong style={{ fontSize: "17px", fontWeight: 900 }}>
                      <i>"Golden Age"</i>
                    </strong>
                  </p>
                }
                header={"King of the beat"}
              />
              <Description
                description={
                  <p style={{ marginTop: 20 }}>
                    Tango music can be traced back to the 1850s, but during the Guardia Nueva
                    period, (1925-1935) Tango dancing had started to decline. The musicians began
                    focusing less on the beat and more on the melodies as people were buying records
                    and listening to tango on the radio. Dancing was no longer the primary concern.
                    <br />
                    Then, in the mid-1930s, Juan d'Arienzo exploded onto the scene returning to the
                    driving beat of the Guardia Vieja (1895-1925). D'Arienzo was seen as a step
                    backwards by many tango musicians and fans of the music, but the dancer's loved
                    him and responded by returning to the dance floor and packing the dance salons.
                  </p>
                }
              />

              <Button href='#'>
                Learn more{" "}
                <ArrowForwardIcon fontSize='small' sx={{ ml: "5px", verticalAlign: "middle" }} />
              </Button>

              {/* <Button href='#'>
                Learn more <i class='fas fa-arrow-right'></i>
              </Button> */}
            </Grid>
            {/* </div> */}
          </Grid>
        </Container>
        <SVG>
          {/* rgb(8,106,215) */}
          <path
            fill-rule='evenodd'
            opacity='0.039'
            fill='rgba(17,17,17, .95)'
            d='M-0.000,232.999 C-0.000,232.999 239.131,-52.566 575.000,47.000 C910.869,146.565 1087.000,55.653 1231.000,19.999 C1375.000,-15.654 1800.820,-31.520 1915.000,232.999 C1445.000,232.999 -0.000,232.999 -0.000,232.999 Z'
          ></path>
        </SVG>
      </Section>
      {/* End of Features */}
      <ScrollUp />
      <Footer />
    </Fragment>
  );
}

export default MusicLyrics;
