import React from "react";
import { styled } from "@mui/material";

const FeatureBox = styled("div")({
  borderRadius: "7px",
  backgroundColor: "#fff",
  WebkitBoxShadow: "0px 10px 60px 0px rgba(200, 226, 255, 0.45)",
  boxShadow: "0px 10px 60px 0px rgba(200, 226, 255, 0.45)",
  overflow: "hidden",
  marginTop: "30px",
  padding: "30px 35px",
  display: "flex",
  alignItems: "center",
  transition: "all 0.3s ease-in-out",
});

const Icon = styled("div")({
  fontSize: "60px",
  lineHeight: 1,
  color: "#086ad7",
  float: "left",
  overflow: "hidden",
  marginRight: "25px",
});

const Content = styled("div")({
  overflow: "auto",
});

const Header = styled("h3")({});

const Description = styled("p")({});

function index(props) {
  const { title, description, ICN } = props;
  return (
    <FeatureBox>
      <Icon>{ICN ? ICN : null}</Icon>
      <Content>
        <Header>{title}</Header>
        <Description>{description}</Description>
      </Content>
    </FeatureBox>
  );
}

export default index;
