import React from "react";
import { styled } from "@mui/material";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

const WidgetBox = styled("div")({
  marginTop: "40px",
});

const Button = styled("a")({
  textDecoration: "none",
  fontWeight: 600,
  outline: "none !important",
  cursor: "pointer",
  fontSize: "17px",
  lineHeight: "32px",
  transition: "all 0.4s ease-in-out",
  position: "relative",
});

const TeamMember = styled("div")({
  padding: "0px 30px 30px",
  position: "relative",
  zIndex: 1,
  borderRadius: "7px",
  textAlign: "center",
  overflow: "hidden",
  margin: "10px",
  marginTop: "40px",
  transition: "all 0.3s ease-in-out",
  "&:before": {
    position: "absolute",
    content: '""',
    height: "75%",
    backgroundColor: "#f5f5f5",
    width: "100%",
    bottom: 0,
    left: 0,
    zIndex: -1,
    borderRadius: "7px",
    transition: "all 0.3s ease-in-out",
  },
  "&:hover": {
    color: "#fff",
    "&:before": {
      backgroundColor: "#af0420",
      transition: "all 0.3s ease-in-out",
    },
  },
});

const Bio = styled("div")({});

const Header = styled("h4")({
  textTransform: "capitalize",
});

const Description = styled("p")({
  textTransform: "capitalize",
});

const Image = styled("img")({
  borderRadius: "7px",
  width: "210px",
  height: "210px",
  backgroundColor: "#eee",
  margin: "0 auto",
  marginBottom: "20px",
  backgroundPosition: "center top",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  position: "relative",
  zIndex: 1,
});

const SocialProfile = styled("div")({
  marginTop: "30px",
});

const SocialLink = styled("a")({
  display: "inline-block",
  width: "36px",
  height: "36px",
  lineHeight: "39px",
  color: "inherit",
  margin: "0 4px",
  textAlign: "center",
  fontSize: "14px",
  textDecoration: "none",
  fontWeight: 600,
  outline: "none !important",
  cursor: "pointer",
  transition: "all 0.4s ease-in-out",
  position: "relative",
});

function index(props) {
  const { name, position, photo, facebook, twitter, youtube, instagram } = props;
  return (
    // <WidgetBox>
    //   <Button href='/our-academy'>
    //     <Image src={src} />
    //   </Button>
    //   <Description
    //     alignment='inherit'
    //     description='Sed ut perspiciatis unde omnis natus voluptatem accusa ntiumy doloremque laudantium.omnis natus voluptatem
    //     accusa ntiumy doloremque laudantium'
    //   />
    // </WidgetBox>

    <TeamMember>
      <Image src={photo} />
      <Bio>
        <Header>{name}</Header>
        <Description>{position}</Description>
      </Bio>
      <SocialProfile>
        {facebook && (
          <SocialLink href={facebook}>
            <FacebookIcon />
          </SocialLink>
        )}
        {twitter && (
          <SocialLink href={twitter}>
            <TwitterIcon />
          </SocialLink>
        )}
        {youtube && (
          <SocialLink href={youtube}>
            <YouTubeIcon />
          </SocialLink>
        )}
        {instagram && (
          <SocialLink href={instagram}>
            <InstagramIcon />
          </SocialLink>
        )}
      </SocialProfile>
    </TeamMember>
  );
}

export default index;
