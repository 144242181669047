import React, { Component } from 'react';

class ChatBox extends Component {
    componentDidMount() {
        // Initialize tawk.to chat widget
        window.Tawk_API = window.Tawk_API || {};
        // window.Tawk_API.visitor = { name: 'John Doe', email: 'john.doe@example.com' };
        window.Tawk_API.key = 'c22d2f246be319074e92117b7f37f07747e4fbf5';
        window.Tawk_LoadStart = new Date();
        (function () {
            var s1 = document.createElement('script');
            var s0 = document.getElementsByTagName('script')[0];
            s1.async = true;
            s1.src = 'https://embed.tawk.to/642355f031ebfa0fe7f53864/1gsl0fcsq';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            s0.parentNode.insertBefore(s1, s0);
        })();
    }

    render() {
        return <div id="tawkchat-widget"></div>;
    }
}

export default ChatBox;
