import React from "react";
import { styled } from "@mui/material";
import { Instagram, Facebook, Twitter, YouTube } from "@mui/icons-material";

const WidgetBox = styled("div")({
  border: "2px solid #ededed",
  boxSizing: "border-box",
  padding: "40px",
  marginBottom: "40px",
});

const WidgetTitle = styled("div")({
  marginBottom: "35px",
  position: "relative",
  paddingLeft: "45px",
  "&:before": {
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
    content: '""',
    height: "4px",
    width: "18px",
    borderRadius: "5px",
    position: "absolute",
    backgroundColor: "#af0420",
  },
  "&:after": {
    left: "22px",
    top: "50%",
    transform: "translateY(-50%)",
    content: '""',
    height: "4px",
    width: "4px",
    borderRadius: "5px",
    position: "absolute",
    backgroundColor: "#af0420",
  },
});

const Title = styled("h3")({});

const Author = styled("div")({
  textAlign: "center",
});

const Image = styled("div")({
  width: "140px",
  height: "140px",
  lineHeight: "140px",
  backgroundColor: "#f2f2f2",
  borderRadius: "50%",
  margin: "0 auto",
  marginBottom: "15px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
});

const Caption = styled("h4")({
  fontSize: "20px",
  lineHeight: "28px",
  color: "#211e3b",
  fontWeight: 700,
});

const Description = styled("p")({
  fontSize: "14px",
  marginTop: "10px",
});

const SocialProfiles = styled("div")({
  display: "flex",
  marginTop: "15px",
  verticalAlign: "middle",
  transition: "all 0.3s ease-in-out",
  justifyContent: "center",
  gap: "10px",
});

function index(props) {
  const { title, image, caption, description, instagram, facebook, twitter, youtube } = props;
  return (
    <WidgetBox>
      <WidgetTitle>
        <Title>{title}</Title>
      </WidgetTitle>
      <Author>
        <Image
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
        <Caption>{caption}</Caption>
        <Description>{description}</Description>
        <SocialProfiles>
          {instagram && <Instagram />}
          {facebook && <Facebook />}
          {twitter && <Twitter />}
          {youtube && <YouTube />}
        </SocialProfiles>
      </Author>
    </WidgetBox>
  );
}

export default index;
