import { Container, Grid, styled } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import faqImage from "../../../assets/images/mina-sea-side.jpg";
import { Accordion, Title } from "../../Elements";

const Section = styled("section")({
  position: "relative",
  backgroundColor: "#f8f8f8",
  padding: "130px 0px",
});

const Image = styled("div")({
  position: "absolute",
  left: 0,
  top: 0,
  width: "50%",
  height: "100%",
  content: '""',
  backgroundColor: "#eee",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
});

function FAQ(props) {
  const theme = useTheme();
  const { dataset, title, header } = props;

  return (
    <Section id='faqs'>
      <Image style={{ backgroundImage: `url(${faqImage})` }} />
      <Container>
        <Grid
          item
          lg={6}
          xl={5}
          sx={{
            [theme.breakpoints.up("lg")]: {
              marginLeft: "50%",
            },
            [theme.breakpoints.up("xl")]: {
              marginLeft: "58.33%",
            },
          }}
        >
          <Grid xs={12} lg={12} sx={{ mb: "40px" }}>
            <Title title={title} header={header} />
          </Grid>
          <Accordion dataset={dataset} />
        </Grid>
      </Container>
    </Section>
  );
}

export default FAQ;
