import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import About from "../../components/Section/About";
import FAQ from "../../components/Section/FAQ";
import Team from "../../components/Section/Team";
import { Fact, PageBanner } from "../../components/Elements";
import ScrollUp from "../../components/ScrollUp";
import ChatBox from "../../components/ChatBox";
import Footer from "../../components/Section/Footer";
import Services from "../../components/Section/Services";
import Testimonial from "../../components/Section/Testimonial";
import { useTranslation } from "react-i18next";

export default function ClassSchedule() {
  const { t } = useTranslation();

  const facts = [
    {
      id: 1,
      count: 743,
      icon: require("../../assets/images/in-studio.png"),
      caption: "trained dancers",
    },
    {
      id: 2,
      count: 30,
      icon: require("../../assets/images/milonga.png"),
      caption: "cozy tango events",
    },
    {
      id: 3,
      count: 10,
      icon: require("../../assets/images/salsa.png"),
      caption: "short sequences",
    },
    {
      id: 4,
      count: 45,
      icon: require("../../assets/images/wedding-dance.png"),
      caption: "wedding dance lessons",
    },
  ];

  const services = [
    {
      id: 1,
      src: require("../../assets/images/class.jpg"),
      title: t("milonga"),
      description: t("weekly-class"),
      overlayTitle: "Overlay Title",
      overlayDescription: "Overlay Description",
    },
    {
      id: 2,
      src: require("../../assets/images/private-lesson.jpg"),
      title: t("private-lessons"),
      description: t("Private Lessons by the top Tango masters"),
      overlayTitle: "Overlay Title",
      overlayDescription: "Overlay Description",
    },
    {
      id: 3,
      src: require("../../assets/images/cozy-event.jpg"),
      title: t("Cozy Tango"),
      description: t("Every year we have a two events in Analiya"),
      overlayTitle: "Overlay Title",
      overlayDescription: "Overlay Description",
    },
  ];

  const data = [
    {
      id: 1,
      summary: t("question1"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer1") }} />,
    },
    {
      id: 2,
      summary: t("question2"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer2") }} />,
    },
    {
      id: 3,
      summary: t("question3"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer3") }} />,
    },
    {
      id: 4,
      summary: t("question4"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer4") }} />,
    },
    {
      id: 5,
      summary: t("question5"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer5") }} />,
    },
    {
      id: 6,
      summary: t("question6"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer6") }} />,
    },
    {
      id: 7,
      summary: t("question7"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer7") }} />,
    },
    {
      id: 8,
      summary: t("question8"),
      detail: <div dangerouslySetInnerHTML={{ __html: t("answer8") }} />,
    },
  ];

  const members = [
    {
      id: 1,
      name: "hassan gogani",
      position: "founder & tango master",
      photo: require("../../assets/images/team1.png"),
      facebook: "#",
      instagram: "#",
      youtube: "#",
    },
    {
      id: 2,
      name: "mina türk",
      position: "founder & teacher",
      photo: require("../../assets/images/team2.png"),
      facebook: "#",
      instagram: "#",
    },
    {
      id: 3,
      name: "arham eskafi",
      position: "tech guy",
      photo: require("../../assets/images/team4.png"),
      facebook: "#",
      twitter: "#",
      instagram: "#",
    },
    {
      id: 4,
      name: "parisa khaleghi",
      position: "teacher assistant",
      photo: require("../../assets/images/team3.png"),
      facebook: "#",
      instagram: "#",
    },
  ];

  const testimonials = [
    {
      id: 1,
      name: "salman ahmed",
      position: "consultant",
      photo: require("../../assets/images/salman-photo.jpg"),
      review:
        "Tango Atölyesi offers a fun and rewarding experience with elegant dance, supportive instructors, a welcoming community, and the opportunity to improve your confidence and meet new friends.",
    },
    {
      id: 2,
      name: "sinem kartal",
      position: "student of BAU",
      photo: require("../../assets/images/sinem-photo.jpg"),
      review:
        "Elegant dance, supportive instructors, new friends, and improved confidence. Tango Atölyesi is a must-try for a fun and rewarding experience.",
    },
  ];

  return (
    <Fragment>
      <ChatBox />
      <Navbar />
      <PageBanner
        text='Schedule'
        header='Class Schedule'
        breadcrumbs={[
          { label: "Home", url: "/" },
          { label: "In-Person Coruses", url: "#" },
          { label: "Schedule", url: "#" },
        ]}
      />
      <About
        title={"About Our Class"}
        header={"Check all of our class schedule"}
        description={
          <>
            <p>
              Are you ready to add some sizzle to your dance moves? Look no further than our tango
              dance classes! No matter your skill level, we have the expertise to take you from
              beginner to pro. And the best part? We've made sure our classes are accessible to
              everyone with proper planning. <br />
              But that's not all! We also offer Salsa Bachata, and Sirtaki dance classes to give you
              a diverse range of options to choose from. And the fun doesn't end there! You can even
              join us for our night parties every Wednesday, Friday, and Saturday. So, why wait?
              Come dance the night away with us!
            </p>
          </>
        }
        image={require("../../assets/images/schedule-all.jpg")}
        goals={[
          {
            id: 1,
            title: "What facilities are available in parties and classes?",
            description: (
              <>
                <p>
                  In the classes, you can order your hot or cold drink, enjoy dressing, bathroom,
                  air conditioning and open space.
                </p>
                <p>
                  During celebrations and night parties, you have all these facilities and you will
                  enjoy the presence of a professional DJ and exclusive music.
                </p>
              </>
            ),
          },
        ]}
        showFeatures={true}
      />
      <FAQ
        title={"You've Got Questions???"}
        header={"Let’s See If We’ve Got Answers."}
        dataset={data}
      />
      <Team
        title={"Exclusive Members"}
        header={"Meet Our Experience Team Members"}
        members={members}
        allMemberURL={"#"}
      />
      {/* <Fact facts={facts} />
      <Services
        services={services}
        title='Popular class & events'
        header='Learn tango with the highest publicly rated tango school in Istanbul'
        style={{
          position: "relative",
          zIndex: 1,
          color: "white",
          textAlign: "center",
        }}
      />
      <Testimonial
        testimonials={testimonials}
        bgr={require("../../assets/images/testimonial-bg.jpg")}
      /> */}
      <ScrollUp />
      <Footer />
    </Fragment>
  );
}
